import React from 'react'
import FeaturedAndFooter from "./Home/FeaturedAndFooter"
import NavbarHomePage from './Home/NavbarHomePage';
import DeleteAccount from './Account/DeleteAccount';
import AccountMainContent from './Account/AccountMainContent';

function DeleteMyAccount() {
  return (
        <div className="homePage myaccountpage">
            <NavbarHomePage />
            <AccountMainContent />
            <DeleteAccount />
            <div className="blogFooter">
            <FeaturedAndFooter />
            </div>
        </div>
    )
}

export default DeleteMyAccount
