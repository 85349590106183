import React,{useState, useEffect} from "react";
import { Assets } from "../Assets";
import { Link, useNavigate } from "react-router-dom";
import { GetAPI } from "../utility/APiCall";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function OurTeamDoctor() {
  const navigate = useNavigate();

  const [isFetching, setIsFetching] = useState(false);
  const [filteredDoctors, setFilteredDoctors] = useState([]);
 //const { itemsPerPage, setItemsPerPage } = props;
 const [activePage, setActivePage] = useState(3)
 const [columnSorter, setColumnSorter] = useState(null);
 //const [itemsPerPage, setItemsPerPage] = useState('')
 const [docInfo, setUsers] = useState([]);
 const [selectedLanguages, setSelectedLanguages] = useState([]);
 const [selectedGender, setSelectedGender] = useState([]);
 const [selecteSpeciality, setSelectedSpeciality] = useState([]);
 const [originalUsersData, setOriginalUsersData] = useState([]);
 const [updatedData, setUpdatedData] = useState([]);
 const [records, setRecords] = useState([])
 const [visible, setVisible] = useState({ form: false, alert: false });
 const [visibleview, setVisibleview] = useState(false);
 const [pagination, setPagination] = useState({
   activePage: 1,
   columnSorter: null,
   itemsPerPage: 10,
   totalListCount: docInfo.length,
   search: '',
 });
console.log('pagination',pagination);
 const [error, setError] = useState(false);
 const [flag, setFlag] = useState(false)
 
 const table_list = (callback) => {
        let params = new URLSearchParams();
        pagination?.columnSorter &&
          pagination?.columnSorter.column &&
          params.append(
            'sort',
            `${pagination?.columnSorter.column}%${pagination?.columnSorter.state}`
          );
        pagination?.search && params.append('search', `${pagination?.search}`);
        const url = `/teleconsultation/list_doctors?page=${pagination?.activePage}&limit=${
          pagination?.itemsPerPage
        }&${params?.toString()}`;
        GetAPI(url, (res) => {
          if (res?.status == 1) {
            setUsers(res?.data);
            const originalData = res?.data;
            setOriginalUsersData(originalData);
            const totalListCount = res?.total;
            console.log('totalListCount',totalListCount);
            setPagination({
              ...pagination,
              totalListCount: res?.total,
            });
            // Call the callback function to indicate completion
          if (callback) {
            callback();
          }
          }
        });
      };
      
      useEffect(() => {
        if (!isFetching) {
          setIsFetching(true);
          // Call table_list with a callback function
          table_list(() => {
            setIsFetching(false);
          });
        }
      }, [flag, pagination.activePage, pagination.columnSorter, pagination.itemsPerPage, pagination.search]);

  const settings = {
    slidesToShow: 4,
    autoplaySpeed: 6000,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const getDocId = (id) =>{
    // console.log('Doctor id',id);
    sessionStorage.setItem('DocId', id);
     navigate('/teleconsultation/book-specialist');
     }

  return (
    <div className="doctorlistcontainer">
      <div className="grid_dr_box">
        <h5>Our Team</h5>
        <h2>
          Meet Our Dedicated Team of <span>Healthcare Professional</span>
          <Link to={'/teleconsultation/select-specialist'} className="text-right">View All</Link>
        </h2>
          <Slider {...settings}>
          {docInfo.map(item => (
          <div>
          <div className="carddetails" onClick={() => getDocId(item.id)}>
          {item.doctor_image ? (
          <img src={item.doctor_image} alt="Doctor" className='' />
          ) : (
          <img alt="Default" className='' src={Assets.vectorDefault} />
          )}
          <div className="card-info">
          <h5>{item.name}</h5>
          <p>{item.parent_cat}</p>
          <p>{ item.rating }</p>
          </div>
          </div>
          </div>
          ))}
          </Slider>
      </div>
    </div>
  );
}

export default OurTeamDoctor;
