import React,{useEffect, useState, useMemo, useRef} from 'react';
import { Assets } from '../Assets';
import { Link } from 'react-router-dom';
import { GetAPI } from '../utility/APiCall';
import { useNavigate } from 'react-router-dom';
import SuccessPopup from '../SignupPopup/SuccessPopup';
import PopupLogin from '../LoginPopup/PopupLogin';
import PopupLoginOTP from '../LoginPopup/PopupLoginOTP';
import { PostAPI } from "../utility/APiCall";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useCookie from "../cookies/Cookie";
import Cookies from 'js-cookie';
import { useName } from '../Context/AppContextProvider';
import BookingConfirmation from './BookingConfirmation';
const SearchSelectSpecialist = () =>{

   const navigate = useNavigate();

   const [isOpen, setIsOpen] = useState(false);
   const [filtersApplied, setFiltersApplied] = useState(false);
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [showSignUpPopup, setShowSignUpPopup] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showLoginOTPPopup, setShowOTPPopup] = useState(false);
    const [isOTPReceived, setIsOTPReceived] = useState(false);
    const [isOTPEntered, setIsOTPEntered] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleClose = () => {
        setIsOpen(false);
        setShowSuccessPopup(false);
    };

    const openLoginPopup = () => {
        setShowLoginPopup(true);
        closeLoginOTPPopup();
        setShowSignUpPopup(false);
    };

    const openSuccessPopup = () =>{

    }

    const closeSuccessPopup = () =>{
      setShowSuccessPopup(false);
    }
    
    const closeLoginPopup = () => {
        setShowLoginPopup(false);
    };

    const openSignUpPopup = () => {
        setShowSignUpPopup(true);
        setShowLoginPopup(false);
    };
    
    const closeSignUpPopup = () => {
        setShowSignUpPopup(false);
        setShowSignUpPopup(true);
    };
    
    const openLoginOTPPopup = () => {
        setShowOTPPopup(true);
        closeLoginPopup();
    };
    
    const closeLoginOTPPopup = () => {
        setShowOTPPopup(false);
    };

    window.onscroll = () => { scrollFunction() };

    function scrollFunction() {
        const navbar = document.getElementById("laiqaNavbar");
        const logo = document.getElementById("id_laiqaLogo");
      
        if (!navbar || !logo) {
          console.error("Elements with IDs 'laiqaNavbar' or 'id_laiqaLogo' not found");
          return;
        }
      
        if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
          navbar.style.padding = "0 0";
          navbar.style.borderRadius = "0 0 10px 10px";
          navbar.style.backgroundColor = "#ffffff";
          logo.style.transform = "scale(0.7)";
          logo.style.transition = "transform 0.2s";
        } else {
          navbar.style.padding = "36px 0";
          navbar.style.borderRadius = "0 0 40px 40px";
          navbar.style.backgroundColor = "#ffffffcc";
          logo.style.transform = "scale(1)";
          logo.style.transition = "transform 0.2s";
        }
      }

    const [isMobileConfirmed, setIsMobileConfirmed] = useState(false);
    const [data, setData] = useState({
    phone: '',
    });

    // const [userName, setUserName] = useCookie('user_name', '');
    // const [userId, setUserId] = useCookie('getUID', '');
    // const [NewuserId, setNewUserId] = useCookie('new_user', '');

    const [getUID, setGetUID] = useState();
    const { userName, userId, setUserName, setUserId } = useName();
    // const [userName, setUserName] = useCookie('user_name', '');
    // const [userId, setUserId] = useCookie('getUID', '');
    const [NewuserId, setNewUserId] = useCookie('new_user', '');
    // console.log('getUID',getUID);

    // sessionStorage.setItem('getUID',getUID);

    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [isFormVisible, setFormVisible] = useState(false);
    const [isOtpComplete, setIsOtpComplete] = useState(false);
    const otpInputs = Array.from({ length: 6 }, (_, index) => index);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const inputRefs = otpInputs.map(() => useRef(null));


    useEffect(() => {
        if (isFormVisible){
            inputRefs[0].current.focus();
        }
    }, [isFormVisible]);
    
    const handleChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        if (value !== '' && index < otpInputs.length - 1) {
          inputRefs[index + 1].current.focus();
        }
        const isComplete = newOtp.every((char) => char !== '');
        setIsOtpComplete(isComplete);
    };
    
    const handleBackspace = (index) => {
        if (otp[index] === '') {
            if (index > 0) {
              inputRefs[index - 1].current.focus();
            }
        }
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);

        const isComplete = newOtp.every((char) => char !== '');
        setIsOtpComplete(isComplete);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity()) {
          if (!isMobileConfirmed) {
            // If mobile is not confirmed yet, call "confirm_mobile" API
            const confirmMobileData = new FormData();
            //confirmMobileData.append('user_id', data?.user_id);
            confirmMobileData.append('phone', data?.phone);
            console.log('confirmMobileData:', confirmMobileData);
            sessionStorage.setItem('phone', data?.phone);
            PostAPI({ url: '/web/trigger_sign_up_otp', data: confirmMobileData }, (confirmMobileResponse) => {
                //console.log('confirmMobileResponse:', confirmMobileResponse);
                //toast.success(confirmMobileResponse.message);
                //alert('success');
                if (confirmMobileResponse.status == 1) {
                  openLoginOTPPopup(true);
                  const getuser_id = confirmMobileResponse?.data?.user_id;
                  const new_user = confirmMobileResponse?.data?.new_user;
                  sessionStorage.setItem('getUID',getuser_id);
                  setUserId(getuser_id);
                  setGetUID(getuser_id);
                  setNewUserId(new_user);
                  // Set isOTPReceived to true when OTP is sent
                  setIsOTPReceived(true);
                  toast.success('OTP sent to your mobile number');
                } else {
                  // Handle failure
                  if (confirmMobileResponse.status == 0) {
                    toast.error(confirmMobileResponse.message);
                    alert(confirmMobileResponse.message);
                    //toast.success('User Already Logged In. Please Log Out First');
                    console.log('failure:', confirmMobileResponse.message);
                  }
                 
                  // alert('');
                  // console.log('failure:', confirmMobileResponse.message);
              }
            });
          } 
        }
        //resetForm();
      };
      useEffect(() => {
          // const getUID = sessionStorage.getItem('getUID');
          // console.log('getUID:', getUID);
        }, []);
      
      const handleFormSubmitotp = async (event) => {
          event.preventDefault();
          const form = event.currentTarget;
          if (form.checkValidity()) {
             // If mobile is confirmed, call "verify_confirm_mobile" API
             
             const verifyData = new FormData();
             verifyData.append('user_id', getUID);
             verifyData.append('phone', data?.phone);
             verifyData.append('otp', otp.join(''));
       
             PostAPI({ url: '/web/verify_web_login', data: verifyData }, (verifyResponse) => {
              console.log('verifyResponse:', verifyResponse);
              if (verifyResponse.status === 1) {
                if(NewuserId == 0){
                  toast.success(verifyResponse.message);
                  const user_name = verifyResponse?.data?.user_name;
                  const user_id = verifyResponse?.data?.id;
                  sessionStorage.setItem('user_name', user_name);
                  sessionStorage.setItem('getUID', user_id);
                  setUserName(user_name);
                  setUserId(user_id);
                  toast.success(verifyResponse?.message);
                  closeLoginOTPPopup();
                } else if(NewuserId == 1){
                 // openLoginOTPPopup(false);
                  setShowSignUpPopup(true);
                  closeLoginOTPPopup();
                }else{
                  alert('check backend');
                }
              } else {
                 toast.error(verifyResponse?.message);
              }
           });
      
           setIsOTPEntered(true); 
           // Set isOTPEntered to true when OTP is entered
          }
          //resetForm();
          // else if(){
          //   setShowSignUpPopup(true);
          // } 
      
        };
        const phone = sessionStorage.getItem('phone');
        const [dataSignup, setDtatSignUp] = useState({
          user_name:'',
          user_phone:phone,
          user_email:'',
          user_dob:'',
        });
      
        const handleFormSubmitSignup = async (event) => {
          event.preventDefault();
          const form = event.currentTarget;
          if (form.checkValidity()) {
             // If mobile is confirmed, call "verify_confirm_mobile" API
             const verifyData = new FormData();
            // verifyData.append('user_id', getUID);
            //const phone = sessionStorage.getItem('phone');
             verifyData.append('user_id', getUID);
             verifyData.append('user_name', dataSignup?.user_name);
             //verifyData.append('user_phone', phone);
             verifyData.append('user_phone', data?.phone);
             verifyData.append('user_email', dataSignup?.user_email);
             verifyData.append('user_dob', dataSignup?.user_dob);
      
             PostAPI({ url: '/web/new_user_sign_up', data: verifyData }, (verifyResponse) => {
              console.log('signupverifyResponse:', verifyResponse);
              if (verifyResponse.status == 1) {
                 toast.success(verifyResponse.message);
                 setShowSignUpPopup(false);
                 setShowSuccessPopup(true);
                 setUserName(dataSignup?.user_name);
              } else {
                 toast.error(verifyResponse?.message);
              }
           });
          }
          //resetForm();
        };
        
      
      //  const userName = sessionStorage.getItem('user_name');
      //  const getuser = sessionStorage.getItem('getUID');
      
      //const navigate = useNavigate();
      
      //  const getToken = sessionStorage.getItem("sessionToken");
      //   const UserId = sessionStorage.getItem('getUID');
      
      //   const [values, setValues] = useState({
      //     user_id:getUID,
      //     accessToken:getToken,
      //   });
      
      function handleLogOut() {
        //  alert('check')
            //const getUID = sessionStorage.getItem('getUID');
            //const getUID = sessionStorage.getItem('getUID');
            const getUID = Cookies.get('getUID');
            const url = '/web/user_web_logout';
            const formData = new FormData();
            formData.append('user_id', getUID);
            //formData.append('accessToken', values?.accessToken);
            const params = { url, data: formData };
            //sessionStorage.clear();
            PostAPI(params, (res) => {
            // Assuming 'token' is the authentication token received from the server
            //console.log('token', getToken);
              // toast.success(res?.message);
              //sessionStorage.clear();
              if (res.status == 1) {
                toast.success(res?.message);
                //sessionStorage.setItem('sessionToken', getToken);
                sessionStorage.clear();
                // Clear the cookies
                Cookies.remove('user_name');
                Cookies.remove('getUID');
                Cookies.remove('new_user'); 
                setTimeout(() => {
                  navigate('/');
                  // Reload the page
                  window.location.reload();
                }, 2000);
              } else {
                toast.error(res?.message);
              }
            });
            //sessionStorage.setItem("sessionToken", getToken);
           
        }

   const [isFetching, setIsFetching] = useState(false);
   const [filteredDoctors, setFilteredDoctors] = useState([]);
  //const { itemsPerPage, setItemsPerPage } = props;
  const [activePage, setActivePage] = useState(3)
  const [columnSorter, setColumnSorter] = useState(null);
  //const [itemsPerPage, setItemsPerPage] = useState('')
  const [docInfo, setUsers] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedGender, setSelectedGender] = useState([]);
  const [selecteSpeciality, setSelectedSpeciality] = useState([]);
  const [originalUsersData, setOriginalUsersData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [records, setRecords] = useState([])
  const [visible, setVisible] = useState({ form: false, alert: false });
  const [visibleview, setVisibleview] = useState(false);
  const [pagination, setPagination] = useState({
    activePage: 1,
    columnSorter: null,
    itemsPerPage: 10,
    totalListCount: docInfo.length,
    search: '',
  });
console.log('pagination',pagination);
  const [error, setError] = useState(false);
  const [flag, setFlag] = useState(false)

    const [dataDetails, setDataDetails] = useState({})

    // const [data, setData] = useState({
        
    // });

    const filterPlus = Assets.filterPlus
    const filterPlusWhite = Assets.filterPlusWhite

    const [isHovered, setIsHovered] = useState(false);
    const [isAscending, setIsAscending] = useState(true);
    const [buttonClicked, setButtonClicked] = useState(false);

    const getCatId = sessionStorage.getItem('getCatId');

    console.log('getCatId>>',getCatId);

    //const userId = sessionStorage.getItem('getUID');

    const [filters, setFilters] = useState({
        language: [],
        concern: [],
        speciality: [],
        gender: []
    });

    const handleFilterClick = (filterCategory, value) => {
        // Toggle the selected value in the filters state
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterCategory]: prevFilters[filterCategory].includes(value)
                ? prevFilters[filterCategory].filter(item => item !== value)
                : [...prevFilters[filterCategory], value]
        }));
    };

    const languageItems = [
        { id: 1, language: "Hindi", src: filterPlus, hoverSrc: filterPlusWhite },
        { id: 2, language: "English", src: filterPlus, hoverSrc: filterPlusWhite },
        { id: 3, language: "Tamil", src: filterPlus, hoverSrc: filterPlusWhite },
        { id: 4, language: "Telugu", src: filterPlus, hoverSrc: filterPlusWhite },
        { id: 5, language: "Marathi", src: filterPlus, hoverSrc: filterPlusWhite },
        { id: 6, language: "Punjabi", src: filterPlus, hoverSrc: filterPlusWhite },
    ];

    const concernItems = [
        { id: 1, concern: "Mensural", src: filterPlus, hoverSrc: filterPlusWhite },
        { id: 2, concern: "General", src: filterPlus, hoverSrc: filterPlusWhite },
        { id: 3, concern: "Diet Concern", src: filterPlus, hoverSrc: filterPlusWhite },
        { id: 4, concern: "Fitness Related", src: filterPlus, hoverSrc: filterPlusWhite },
        { id: 5, concern: "Sleep or Stress Related", src: filterPlus, hoverSrc: filterPlusWhite },
        { id: 6, concern: "Others", src: filterPlus, hoverSrc: filterPlusWhite },
    ];

    const specialityItems = [
        { id: 1, speciality: "GP", src: filterPlus, hoverSrc: filterPlusWhite },
        { id: 2, speciality: "Gynacologist", src: filterPlus, hoverSrc: filterPlusWhite },
        { id: 3, speciality: "Psychologist", src: filterPlus, hoverSrc: filterPlusWhite },
        { id: 4, speciality: "Endocrinologist", src: filterPlus, hoverSrc: filterPlusWhite },
        { id: 5, speciality: "Dermatologist", src: filterPlus, hoverSrc: filterPlusWhite },
        { id: 6, speciality: "Dietician", src: filterPlus, hoverSrc: filterPlusWhite },
        { id: 7, speciality: "Ayurveda", src: filterPlus, hoverSrc: filterPlusWhite },
    ];

    const genderItems = [
        { id: 1, gender: "Male", src: filterPlus, hoverSrc: filterPlusWhite },
        { id: 2, gender: "Female", src: filterPlus, hoverSrc: filterPlusWhite },
    ];

    const table_list = (callback) => {
        let params = new URLSearchParams();
        pagination?.columnSorter &&
          pagination?.columnSorter.column &&
          params.append(
            'sort',
            `${pagination?.columnSorter.column}%${pagination?.columnSorter.state}`
          );
        pagination?.search && params.append('search', `${pagination?.search}`);
        const url = `/teleconsultation/list_doctors?page=${pagination?.activePage}&limit=${
          pagination?.itemsPerPage
        }&${params?.toString()}`;
        GetAPI(url, (res) => {
          if (res?.status == 1) {
            setUsers(res?.data);
            const originalData = res?.data;
            setOriginalUsersData(originalData);
            const totalListCount = res?.total;
            console.log('totalListCount',totalListCount);
            setPagination({
              ...pagination,
              totalListCount: res?.total,
            });
            // Call the callback function to indicate completion
          if (callback) {
            callback();
          }
          }
        });
      };
      
      useEffect(() => {
        if (!isFetching) {
          setIsFetching(true);
          // Call table_list with a callback function
          table_list(() => {
            setIsFetching(false);
          });
        }
      }, [flag, pagination.activePage, pagination.columnSorter, pagination.itemsPerPage, pagination.search]);

    const getDocId = (id) =>{
   // console.log('Doctor id',id);
   sessionStorage.setItem('DocId', id);
    navigate('/teleconsultation/book-specialist');
    }
    // selecteSpeciality, setSelectedSpeciality
    useEffect(() => {
        setFilteredDoctors(filterDoctors());
        setFiltersApplied(selecteSpeciality.length > 0 || selectedLanguages.length > 0 || selectedGender.length > 0 || sessionStorage.getItem('getCatId') !== null);
    }, [selecteSpeciality, selectedLanguages, selectedGender, isAscending]);

    // const filterDoctors = () => {
    //     const getCatId = sessionStorage.getItem('getCatId');
    //     console.log('getCatId>>', getCatId);

    //     let filteredDoctors = docInfo;
    //     if (selectedLanguages.length > 0) {
    //         filteredDoctors = filteredDoctors.filter(doctor =>
    //             selectedLanguages.some(lang => doctor.language.includes(lang))
    //         );
    //     }
    //     if (selectedGender.length > 0) {
    //         filteredDoctors = filteredDoctors.filter(doctor =>
    //             selectedGender.includes(doctor.gender)
    //         );
    //     }
    //     if (selecteSpeciality.length > 0) {
    //         filteredDoctors = filteredDoctors.filter(doctor =>
    //             selecteSpeciality.includes(doctor.parent_cat)
    //         );
    //     }
    //     // Filter based on sessionStorage value
    // if (getCatId) {
    //     filteredDoctors = filteredDoctors.filter(doctor =>
    //         doctor.parent_cat === getCatId
    //     );
    // }
    //     return filteredDoctors;
    // };

    // const filterDoctors = () => {
    //     const getCatId = sessionStorage.getItem('getCatId');
    //     console.log('getCatId>>', getCatId);

    //     let filteredDoctors = docInfo;
    //     if (selectedLanguages.length > 0) {
    //         filteredDoctors = filteredDoctors.filter(doctor =>
    //             selectedLanguages.some(lang => doctor.language.includes(lang))
    //         );
    //     }
    //     if (selectedGender.length > 0) {
    //         filteredDoctors = filteredDoctors.filter(doctor =>
    //             selectedGender.includes(doctor.gender)
    //         );
    //     }
    //     if (selecteSpeciality.length > 0) {
    //         filteredDoctors = filteredDoctors.filter(doctor =>
    //             selecteSpeciality.includes(doctor.parent_cat)
    //         );
    //     }
    //     // Filter based on sessionStorage value
    //     if (getCatId) {
    //         filteredDoctors = filteredDoctors.filter(doctor =>
    //             doctor.parent_cat === getCatId
    //         );
    //     }

    //     // Sort the filtered doctors
    //     const sortedDoctors = filteredDoctors.sort((a, b) => {
    //         if (isAscending) {
    //             return a.name.localeCompare(b.name); // Change 'name' to the property you want to sort by
    //         } else {
    //             return b.name.localeCompare(a.name); // Change 'name' to the property you want to sort by
    //         }
    //     });

    //     return sortedDoctors;
    // };

    const filterDoctors = () => {
        const getCatId = sessionStorage.getItem('getCatId');
        console.log('getCatId>>', getCatId);

        let filteredDoctors = docInfo;
        if (selectedLanguages.length > 0) {
            filteredDoctors = filteredDoctors.filter(doctor =>
                selectedLanguages.some(lang => doctor.language.includes(lang))
            );
        }
        if (selectedGender.length > 0) {
            filteredDoctors = filteredDoctors.filter(doctor =>
                selectedGender.includes(doctor.gender)
            );
        }
        if (selecteSpeciality.length > 0) {
            filteredDoctors = filteredDoctors.filter(doctor =>
                selecteSpeciality.includes(doctor.parent_cat)
            );
        }
        // Filter based on sessionStorage value
        if (getCatId) {
            filteredDoctors = filteredDoctors.filter(doctor =>
                doctor.parent_cat === getCatId
            );
        }

        // Sort the filtered doctors
        const sortedDoctors = filteredDoctors.sort((a, b) => {
            if (isAscending) {
                return a.name.localeCompare(b.name); // Change 'name' to the property you want to sort by
            } else {
                return b.name.localeCompare(a.name); // Change 'name' to the property you want to sort by
            }
        });

        return sortedDoctors;
    };

    const handleSortButtonClick = () => {
        setIsAscending(!isAscending);
    };

    useEffect(() => {
        const getCatId = sessionStorage.getItem('getCatId');
        if (getCatId) {
            setFilteredDoctors(filterDoctors());
        }
    }, [docInfo]);

// Function to handle language filter selection
const handleLanguageFilter = (language) => {
    setSelectedLanguages(prevLanguages => {
        if (prevLanguages.includes(language)) {
            return prevLanguages.filter(lang => lang !== language);
        } else {
            return [...prevLanguages, language];
        }
    });
};

        const LanguageItem = ({ item, handleLanguageFilter, isSelected }) => {
            return (
                <li>
                    <button onClick={() => handleLanguageFilter(item.language)} style={{
                        backgroundColor: isSelected ? '#D27060' : '#ffffff',
                        color: isSelected ? '#ffffff' : '#100F11',
                        flexFlow: isSelected ? 'row-reverse' : 'row',
                        boxShadow: isSelected ? "0 6px 25px #B66F6552" : "",
                    }} >
                        {isSelected ? <img src={Assets.filterCrossWhite} /> : <img src={Assets.filterPlus} />}
                        <p>{item.language}</p>
                    </button>
                </li>
            );
        };

    const ConcernItem = ({ item }) => {
        let concernFilterClicked = useState(true);
        const [buttonClicked, setButtonClicked] = useState(false);
        const handleButtonClick = () => {
            if (concernFilterClicked){
                if (!filters.concern.includes(item.concern)){
                    filters.concern.push(item.concern);
                }
                else{
                    const index = filters.concern.indexOf(item.concern);
                    filters.concern.splice(index, 1);
                }
            }
            console.log(filters);
            console.log(filters.concern);
            setButtonClicked(!buttonClicked);
        };
        return (
            <li>
                <button onClick={ handleButtonClick } style={{ 
                    backgroundColor: buttonClicked ? '#D27060' : '#ffffff',
                    color: buttonClicked ? '#ffffff' : '#100F11', 
                    flexFlow : buttonClicked ? 'row-reverse' : 'row',
                    boxShadow: buttonClicked ? "0 6px 25px #B66F6552" : "",
                    }} >
                        { buttonClicked ? <img src={ Assets.filterCrossWhite } /> : <img src={ Assets.filterPlus } /> }
                        <p>{ item.concern }</p>
                </button>
            </li>
        );
    }

    const handleSpecialityFilter = (speciality) => {
        sessionStorage.clear('getCatId');
        setSelectedSpeciality(prevSpeciality => {
            if (prevSpeciality.includes(speciality)) {
                return prevSpeciality.filter(special => special !== speciality);
            } else {
                return [...prevSpeciality, speciality];
            }
        });
    };

    // const clearAll = () =>{
    //     sessionStorage.clear('getCatId');
    //     //handleSpecialityFilter();
    // }

    const clearAll = () => {
        setSelectedSpeciality([]);
        setSelectedLanguages([]);
        setSelectedGender([]);
        sessionStorage.removeItem('getCatId');
        setFilteredDoctors(docInfo);
    };

    // const SpecialityItem = ({ item, handleSpecialityFilter, isSelected }) => {
    //     return (
    //         <li>
    //             <button onClick={() => handleSpecialityFilter(item.speciality)} style={{
    //                 backgroundColor: isSelected ? '#D27060' : '#ffffff',
    //                 color: isSelected ? '#ffffff' : '#100F11',
    //                 flexFlow: isSelected ? 'row-reverse' : 'row',
    //                 boxShadow: isSelected ? "0 6px 25px #B66F6552" : "",
    //             }} >
    //                 {isSelected ? <img alt='' src={Assets.filterCrossWhite} /> : <img alt='' src={Assets.filterPlus} />}
    //                 <p>{item.speciality}</p>
    //             </button>
    //         </li>
    //     );
    // };

    const SpecialityItem = ({ item, handleSpecialityFilter, isSelected }) => {
        const getCatId = sessionStorage.getItem('getCatId');
    
        // Check if the current speciality matches the getCatId
        const isSelectedBasedOnCatId = getCatId == item.speciality;
        const finalIsSelected = isSelected || isSelectedBasedOnCatId;
        return (
            <li>
             <button onClick={() => handleSpecialityFilter(item.speciality)} style={{
                backgroundColor: finalIsSelected ? '#D27060' : '#ffffff',
                color: finalIsSelected ? '#ffffff' : '#100F11',
                flexFlow: finalIsSelected ? 'row-reverse' : 'row',
                boxShadow: finalIsSelected ? "0 6px 25px #B66F6552" : "",
            }} >
                {finalIsSelected ? <img alt='' src={Assets.filterCrossWhite} /> : <img alt='' src={Assets.filterPlus} />}
                <p>{item.speciality}</p>
            </button>
        </li>
        );
    };

// Function to handle gender filter selection
const handleGenderFilter = (gender) => {
    setSelectedGender(prevGender => {
        if (prevGender.includes(gender)) {
            return prevGender.filter(gend => gend !== gender);
        } else {
            return [...prevGender, gender];
        }
    });
};

const GenderItem = ({ item, handleGenderFilter, isSelected }) => {
    return (
        <li>
            <button onClick={() => handleGenderFilter(item.gender)} style={{
                backgroundColor: isSelected ? '#D27060' : '#ffffff',
                color: isSelected ? '#ffffff' : '#100F11',
                flexFlow: isSelected ? 'row-reverse' : 'row',
                boxShadow: isSelected ? "0 6px 25px #B66F6552" : "",
            }} >
                {isSelected ? <img alt='' src={Assets.filterCrossWhite} /> : <img alt='' src={Assets.filterPlus} />}
                <p>{item.gender}</p>
            </button>
        </li>
    );
};

    const DoctorInfo = ({ item }) => {
        const [isHovered, setHovered] = useState(false);
        return (
            <li>
                {/* <img src={ item.doctor_image } alt="Doctor" className='Doctor' /> */}
                {item.doctor_image ? (
               <img src={item.doctor_image} alt="Doctor" className='Doctor' />
              ) : (
               <img alt="Default" className='Doctor' src={Assets.vectorDefault} />
              )}
                <div className="selectSpecialistDoctorContent">
                    <p className="selectSpecialistDoctorName">
                        {item.name}
                    </p>
                    <div className="selectSpecialistSpecialityAndExp">
                        <p className="speciality">
                            {item.parent_cat}
                        </p>
                        <p>•</p>
                        <p className="experience">
                            {item.years_experience}   
                        </p>
                    </div>
                    <div className="selectSpecialistDoctorLanguage">
                        <p id="language1">{ item.language[0] }</p>
                        <p>•</p>
                        <p id="language2">{ item.language[1] }</p>
                    </div>
                    <div className="selectSpecialistDoctorRating">
                        <div className="selectSpecialistDoctorRatingWrapper">
                            <img alt='' src={ Assets.ratingStar } />
                            <p>{ item.rating }</p>
                        </div>
                        <p>{ item.numberOfRatings } Ratings</p>
                    </div>
                </div>
                <div className="selectSpecialistBookingInfo">
                    <div className="doctorCharges">
                        <p className="doctorPriceRetail">
                            &#8377;{ item.amount }
                        </p>
                        <p className="doctorPriceMRP">
                            &#8377; ----
                        </p>
                    </div>
                    {userId ? (
                        <button id="bookNowSpecialist"
                            className={ `${isHovered ? 'bookNowTeleconsButtonHovered' : ''}` }
                            onMouseEnter={() => setHovered(true)}
                            onMouseLeave={() => setHovered(false)}
                            onClick={() => getDocId(item.id)}
                            >
                            Book now
                            {isHovered ? <img alt='' src={ Assets.arrowUpRight } /> : <img alt='' src={ Assets.arrowUpRightBlack } /> }
                        </button>   
                        ) : (
                        <button onClick={openLoginPopup} id="bookNowSpecialist"
                            className={ `${isHovered ? 'bookNowTeleconsButtonHovered' : ''}` }
                            onMouseEnter={() => setHovered(true)}
                            onMouseLeave={() => setHovered(false)}
                            >
                            Book now
                            {isHovered ? <img alt='' src={ Assets.arrowUpRight } /> : <img alt='' src={ Assets.arrowUpRightBlack } /> }
                        </button>   
                        )}
                </div>
            </li>
        );
    }

    return(
        <div className="selectSpecialist">
        <SuccessPopup
         show={ showSuccessPopup } handleClose={ closeSuccessPopup } openSuccessPopup={ openSuccessPopup } />
        <PopupLogin 
            handleFormSubmit={handleFormSubmit} 
            getUID={getUID} 
            setGetUID={setGetUID}
            // manuallySetOTP={manuallySetOTP}
            data={data} 
            setData={setData}
            isMobileConfirmed={isMobileConfirmed} 
            setIsMobileConfirmed={setIsMobileConfirmed}
            show={ showLoginPopup } handleClose={ closeLoginPopup } openLoginOTPPopup={ openLoginOTPPopup } />
            <PopupLoginOTP 
            handleFormSubmitotp={handleFormSubmitotp} 
            getUID={getUID} 
            setGetUID={setGetUID}
            data={data} 
            setData={setData}
            isFormVisible={isFormVisible} 
            setFormVisible={setFormVisible}
            otp={otp} 
            setOtp={setOtp}
            isOtpComplete={isOtpComplete}
            setIsOtpComplete={setIsOtpComplete}
            inputRefs={inputRefs}
            otpInputs={otpInputs}
            handleBackspace={handleBackspace}
            handleChange={handleChange}
            show={ showLoginOTPPopup } handleClose={ closeLoginOTPPopup } /> 
            <div className="selectSpecialistHeadingContent">
                <div className="selectSpecialistContentHeading">
                    <p className="areaOfConcernContentHeading1">Choose The Specialist</p>
                    <p className="areaOfConcernContentHeading2">Consult With Top Doctors <b>Across Specialities</b></p>
                </div>
            </div>
            <div className="selectSpecialistFilterAndList">
                <div className="selectSpecialistFilter">
                    <div className="selectSpecialistFilterHeading">
                        <div className="selectSpecialistFilterImage">
                            <img alt='' src={ Assets.filterImage } />
                            <p>Filters</p>
                        </div>
                        <Link onClick={clearAll}>Clear all</Link>
                    </div>
                    <div className="selectSpecialistFilterLanguage">
                        <p className="filterLanguageHeading"> Language </p>
                        <div className="filterLanguageListWrapper">
                            <ul className="filterLanguageListUl">
                            {languageItems.map(item => (
                            <LanguageItem
                                key={item.id}
                                item={item}
                                handleLanguageFilter={handleLanguageFilter}
                                isSelected={selectedLanguages.includes(item.language)}
                            />
                        ))}
                            </ul>
                        </div>
                    </div>
                    <div className="selectSpecialistFilterLanguage">
                        <p className="filterLanguageHeading"> Concern </p>
                        <div className="filterLanguageListWrapper">
                            <ul className="filterLanguageListUl">
                                { concernItems.map(item => (
                                    <ConcernItem key = { item.id } item = { item } />
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="selectSpecialistFilterLanguage">
                        <p className="filterLanguageHeading"> Speciality </p>
                        <div className="filterLanguageListWrapper">
                            <ul className="filterLanguageListUl">
                                { specialityItems.map(item => (
                                    <SpecialityItem key = { item.id } item = { item } 
                                    handleSpecialityFilter={handleSpecialityFilter}
                                    isSelected={selecteSpeciality.includes(item.speciality)}  />
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="selectSpecialistFilterLanguage">
                        <p className="filterLanguageHeading"> Gender </p>
                        <div className="filterLanguageListWrapper">
                            <ul className="filterLanguageListUl">
                                { genderItems.map(item => (
                                    <GenderItem key = { item.id } item = { item }
                                    handleGenderFilter={handleGenderFilter}
                                   isSelected={selectedGender.includes(item.gender)}
                                     />
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="selectSpecialistList">
                    <div className="selectSpecialistListHeader">
                      <p><b>{filtersApplied ? filteredDoctors.length : docInfo.length}</b> Results Found</p>
                        <button id="selectSpecialistSortButton"
                            className={`${isHovered ? 'wcsStatsButtonHovered' : ''}`}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            onClick={handleSortButtonClick}
                            >
                            Sort By
                            {isHovered ? <img src={ Assets.arrowSortWhite } /> : <img src={ Assets.arrowSort } />}
                        </button>
                    </div>
                    <div className="selectSpecialistListWrapper">
                        <ul className="selectSpecialistListUl">
                        {(filtersApplied ? filteredDoctors : docInfo).slice(0, 5).map(item => (
                        <DoctorInfo key={item.id} item={item} />
                        ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchSelectSpecialist;