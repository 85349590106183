import { Assets } from "../Assets";

import { Link } from "react-router-dom"
import { useState } from 'react'

const SelfPrognosisIntro = () => {
    const [isHovered, setIsHovered] = useState(false);

    return(
        <div className="workshopIntroContent">     
            <div className="whyOnlyUsImageContent">
                <div className="workshopIntroImageWrapper">
                    <img alt="Self Prognosis" src={ Assets.selfPrognosisIntroImage } />
                </div>
                <div className="workshopIntroTextContent">
                    <p className="workshopIntroTextHeading">Self Prognosis</p>
                    <p className='womenWellnessTextHeading2'>Decoding Laiqa’s 
                    {/* What is LAIQA'S */}
                     <br /><b>Self Prognosis?   </b></p>
                    <p className="workshopIntroArticle">
                    {/* This is a questionnaire that helps you gain a better understanding of your general wellness. */}
                    Gain insights into your overall wellness with this comprehensive questionnaire designed to assess multiple dimensions of your well-being.
                    </p>
                    <Link to="/selfprognosis/know-your-wellness">
                    <button id="selfPrognosisIntroButton"
                        className={ `${isHovered ? 'bookNowTeleconsButtonHovered' : ''}` }
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        >
                        Start My Assessment
                        {isHovered ? <img src={ Assets.arrowUpRight } /> : <img src={ Assets.arrowUpRightBlack } /> }
                    </button>
                </Link>
                </div>
            </div>
        </div>
    )
}

export default SelfPrognosisIntro;