import { Assets } from "../Assets"
import { Link } from "react-router-dom";
import { PostAPI } from "../utility/APiCall";
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const FooterKyw = () => {
    const [data, setData] = useState({
        user_id:'',
        email:'',
      })
      ///web/mail_subscription
        const scrollToTop = () => {
        // setLoading(true);
        // setTimeout(() => {
        // setLoading(false);
        // }, 2000);
        window.scrollTo({
        top: 0,
        behavior: 'smooth',
        });
        };
      
        const handleFormSubmit = async (event) => {
          event.preventDefault();
          const form = event.currentTarget;
          const user_id = sessionStorage.getItem('getUID');
      
          console.log('UserID Subscription', user_id);
      
          if (form.checkValidity()) {
            if (!user_id) {
              // Show alert if user is not logged in
              alert('Please log in first to subscribe.');
            } else {
              // Proceed with the API call if user_id is present
              const mailSubscriptionData = new FormData();
      
              mailSubscriptionData.append('user_id', user_id);
              mailSubscriptionData.append('email', data?.email);
      
              PostAPI({ url: '/web/mail_subscription', data: mailSubscriptionData }, (confirmSubscriptionData) => {
                if (confirmSubscriptionData.status == 1) {
                  toast.success("Mail Subscribed");
                } else {
                  // Handle failure
                  if (confirmSubscriptionData.status == 0) {
                    toast.error(confirmSubscriptionData.message);
                    alert(confirmSubscriptionData.message);
                    console.log('failure:', confirmSubscriptionData.message);
                  }
                }
              });
            }
          }
        };
    return(
        <div className="kywFooter">
        <div className="footerContent">
            <div className="footerContentWrapper">
              <div className="footerContentWrapperInner">
                <div className="footerBrandingBottomLeft">
                <Link to="/">
                  <img
                    className="footerLaiqaLogoPink"
                    src={Assets.laiqaLogoPink}
                    alt="My Laiqa"
                  />
                  </Link>
                  <p>Download Mobile App today!</p>
                  <Link to="https://apps.apple.com/us/app/period-pal-by-laiqa/id1591718639">
                  <img
                    className="footerDownloadLaiqa"
                    src={Assets.App_store}
                    alt="App store"
                  />
                  </Link>
                  <Link to="https://play.google.com/store/apps/details?id=com.laiqa&pli=1">
                  <img
                    className="footerDownloadLaiqa"
                    src={Assets.play_store}
                    alt="play store"
                  />
                  </Link>
                  <div className="socialMedia">
                    <Link to="https://x.com/i/flow/login?redirect_after_login=%2FOurLaiqa"><img src={Assets.X} alt="X (Twitter)" /></Link>
                    <Link to="https://www.facebook.com/OurLaiqa/"><img src={Assets.facebook} alt="Facebook" /></Link>
                    <Link to="https://www.instagram.com/ourlaiqa/"><img src={Assets.instagram} alt="Instagram" /></Link>
                    {/* <Link to="/"><img src={Assets.linkedin} alt="LinkedIn" /></Link> */}
                  </div>
                </div>
                <div className="footerQuickLinks">
                  <p>Quick Links</p>
                  <ul className="footerQuickLinksUl">
                    <li>
                      <Link
                        onClick={scrollToTop}
                        to="/wellness-plans"
                      >
                        Wellness Plans
                      </Link>
                    </li>
                    <li>
                      <Link onClick={scrollToTop} to="/workshops">
                        Workshop
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={scrollToTop}
                        to="/teleconsultation"
                      >
                        Teleconsultation
                      </Link>
                    </li>
                    <li>
                      <Link onClick={scrollToTop} to="/blog">
                        Blogs
                      </Link>
                    </li>
                    <li>
                      <Link onClick={scrollToTop} to="/about-us">
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={scrollToTop}
                        to="/contact-us"
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="footerEngageAndOther">
                  <p className="footerEngageHeading">Engage</p>
                  <ul className="footerEngageUl">
                    <li>
                      <Link to="https://www.mylaiqa.com">Shop</Link>
                    </li>
                    <li>
                      <Link onClick={scrollToTop} to="/corporate">
                        Corporate
                      </Link>
                    </li>
                  </ul>
                  <p className="footerOtherHeading">Other</p>
                  <ul className="footerEngageUl">
                    <li>
                      <Link to="https://mylaiqa.com/privacy-policy/">
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="https://mylaiqa.com/terms-and-conditions/">
                        Terms &amp; Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="footerNewsletter">
                  <p>Allow us to slip into your inbox</p>
                  <form 
                    onSubmit={handleFormSubmit}
                    name={data?.id ? "update" : "insert"}>
                   <input
                    placeholder="Your email address"
                    className="footerNewsletterEmail"
                    type="text"
                    onChange={(e) => setData({...data, email: e.target.value})} value={data.email}
                    name="email"
                    autocomplete="off"
                   />
                  <button type="submit" className="footerNewsletterSubmitButton">
                    Submit
                  </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
            {/* <div className="footerContent">
                <div className="footerContentWrapper">
                    <div className="footerContentWrapperInner">
                        <div className="footerBrandingBottomLeft">
                            <img className="footerLaiqaLogoPink" src={ Assets.laiqaLogoPink } alt="My Laiqa" />
                            <p>Download Mobile App today!</p>
                            <img className="footerDownloadLaiqa" src={ Assets.downloadFromStore } alt="Download My Laiqa on Android and iOS" />
                            <div className="socialMedia">
                                <img src={ Assets.X } alt="X (Twitter)" />
                                <img src={ Assets.facebook } alt="Facebook" />
                                <img src={ Assets.instagram } alt="Instagram" />
                                <img src={ Assets.linkedin } alt="LinkedIn" />
                            </div>
                        </div>
                        <div className="footerQuickLinks">
                            <p>Quick Links</p>
                            <ul className='footerQuickLinksUl'>
                                <li><a href="/">Wellness Plans</a></li>
                                <li><a href="/">Workshop</a></li>
                                <li><a href="/">Teleconsultation</a></li>
                                <li><a href="/">Blogs</a></li>
                                <li><a href="/">About Us</a></li>
                                <li><a href="/">Contact Us</a></li>
                            </ul>
                        </div>
                        <div className="footerEngageAndOther">
                            <p className="footerEngageHeading">Engage</p>
                            <ul className='footerEngageUl'>
                                <li><a href="https://www.mylaiqa.com">Shop</a></li>
                                <li><a href="/">Corporate</a></li>
                            </ul>
                            <p className="footerOtherHeading">Other</p>
                            <ul className='footerEngageUl'>
                                <li><a href="/">Privacy Policy</a></li>
                                <li><a href="/">Terms &amp; Conditions</a></li>
                            </ul>
                        </div>
                        <div className="footerNewsletter">
                            <p>Allow us to slip into your inbox</p>
                            <input placeholder="Your email address" className="footerNewsletterEmail" type="text" />
                            <button className="footerNewsletterSubmitButton">Submit</button>
                        </div>
                    </div>
                    
                </div>        
            </div> */}
        </div>
    )
}

export default FooterKyw;