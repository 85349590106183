import { Assets } from "../Assets"

import { useState } from 'react';
import { Link } from 'react-router-dom';

import PopupScheduleCall from '../ScheduleCallPopup/PopupScheduleCall'

const OurPlansDirectPlans = () => {

    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const [isHovered3, setIsHovered3] = useState(false);
    const [isHovered4, setIsHovered4] = useState(false);
    const [isHovered5, setIsHovered5] = useState(false);
    const [isHovered6, setIsHovered6] = useState(false);

    const [showScheduleCallPopup, setShowScheduleCallPopup] = useState(false);

    const openScheduleCallPopup = () => {
        setShowScheduleCallPopup(true);
    };
    
    const closeScheduleCallPopup = () => {
        setShowScheduleCallPopup(false);
    };

    return(
        <div className="ourPlansDirectPlans">
            <PopupScheduleCall show={ showScheduleCallPopup } handleClose={ closeScheduleCallPopup } openLoginOTPPopup={ openScheduleCallPopup } />
            <div className="ourPlansTextContent">
                <div className="ourPlansHeading">
                    <div className="ourPlansHeading1">
                        Our Plans
                    </div>
                    <div className="ourPlansHeading2">
                        Laiqa Wellbeing Plans
                    </div>
                </div>
                <div className="ourPlansArticle">
                    <p>LAIQA Wellbeing Plan is designed to help you improve your overall wellness. It allows you to address your health concerns through a customized plan that helps you manage your general wellbeing and achieve ultimate physical and mental health.</p>
                </div>
            </div>

            <div className="directPlansPlansList">
                <div className="selectedPlansPriceList">
                    <div className="checkoutPriceListWrapper">
                        <Link to="/wellness-plans/checkout">
                            <div className="directPlansSilverPlan">
                                <div className="directPlanListItemHeading" id="silverPlanHeading">
                                    <p className="planNameHeading">Silver Plan</p>
                                    <div className="wellnessSilverPlanHeadingPrice">
                                        <b><p>&#8377;3999</p></b>
                                        <p> / 3 months</p>
                                    </div>
                                    <div className="directPlansListItemHeadingButtons">
                                        <button id="directPlansListItemButton1"
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}
                                        >
                                            Buy Plan
                                            {isHovered ? <img src={ Assets.shoppingBagWhite } /> : <img src={ Assets.shoppingBag } /> }
                                        </button>
                                        <button id="directPlansListItemButton2"
                                        onMouseEnter={() => setIsHovered2(true)}
                                        onMouseLeave={() => setIsHovered2(false)}
                                        onClick={ openScheduleCallPopup }
                                        >
                                            Schedule Call
                                            {isHovered2 ? <img src={ Assets.phoneCallWhite } /> : <img src={ Assets.phoneCall } /> }
                                        </button>
                                    </div>
                                </div>
                                <div className="directPlansListContents">
                                    <div className="directPlansListContent1">
                                        <ul>
                                            <li>Differential Diagnosis - One Time - 1 hr</li>
                                            <li>Detailed User Report- <b>Monthly</b></li>
                                            <li><b>Weekly Diet (&) Fitness Plan</b></li>
                                            <li>Created Content Access - Recipe, Fitness, Lifestyle - Weekly</li>
                                            <li>Product Voucher worth Rs. 900 - Free</li>
                                            <li>3 Core Workshop Mental, Physical, Diet</li>
                                        </ul>
                                    </div>
                                    <div className="directPlansListContent2">
                                        <ul>
                                            <li><b>User Progress Report </b> in end of plan</li>
                                            <li><b>Unlimited chat access with coach</b></li>
                                        </ul>
                                            <p className="addOnsText">
                                                Add ons
                                            </p>
                                        <ul style={{ paddingTop:"0" }}>
                                            <li><b>15% Discount on Diagnostic Plan</b></li>
                                            <li><b>20% Discount on Products Purchases</b></li>
                                            <li><b>10% Discount on Products Purchases</b></li>
                                        </ul>
                                    </div>
                                </div>
                                
                            </div>
                        </Link>

                        <Link to="/wellness-plans/checkout">
                            <div className="directPlansGoldPlan">
                                <div className="directPlanListItemHeading" id="goldPlanHeading">
                                    <p className="planNameHeading">Gold Plan</p>
                                    <div className="wellnessSilverPlanHeadingPrice">
                                        <b><p>&#8377;7499</p></b>
                                        <p> / 6 months</p>
                                    </div>
                                    <div className="directPlansListItemHeadingButtons">
                                        <button id="directPlansListItemButton1"
                                        onMouseEnter={() => setIsHovered3(true)}
                                        onMouseLeave={() => setIsHovered3(false)}
                                        >
                                            Buy Plan
                                            {isHovered3 ? <img src={ Assets.shoppingBagWhite } /> : <img src={ Assets.shoppingBag } /> }
                                        </button>
                                        <button id="directPlansListItemButton2"
                                        onMouseEnter={() => setIsHovered4(true)}
                                        onMouseLeave={() => setIsHovered4(false)}
                                        onClick={ openScheduleCallPopup }
                                        >
                                            Schedule Call
                                            {isHovered4 ? <img src={ Assets.phoneCallWhite } /> : <img src={ Assets.phoneCall } /> }
                                        </button>
                                    </div>
                                </div>
                                <div className="directPlansListContents">
                                    <div className="directPlansListContent1">
                                        <ul>
                                            <li>Differential Diagnosis - One Time - 1 hr</li>
                                            <li>Detailed User Report- <b>Monthly</b></li>
                                            <li><b>Weekly Diet (&) Fitness Plan</b></li>
                                            <li>Created Content Access - Recipe, Fitness, Lifestyle - Weekly</li>
                                            <li>Product Voucher worth Rs. 900 - Free</li>
                                            <li>3 Core Workshop Mental, Physical, Diet</li>
                                        </ul>
                                    </div>
                                    <div className="directPlansListContent2">
                                        <ul>
                                            <li><b>User Progress Report </b> in end of plan</li>
                                            <li><b>Unlimited chat access with coach</b></li>
                                        </ul>
                                            <p className="addOnsText">
                                                Add ons
                                            </p>
                                        <ul style={{ paddingTop:"0" }}>
                                            <li><b>15% Discount on Diagnostic Plan</b></li>
                                            <li><b>20% Discount on Products Purchases</b></li>
                                            <li><b>10% Discount on Products Purchases</b></li>
                                        </ul>
                                    </div>
                                </div>
                                
                            </div>
                        </Link>

                        <Link to="/wellness-plans/checkout">
                            <div className="directPlansPlatinumPlan">
                                <div className="directPlanListItemHeading" id="platinumPlanHeading">
                                    <p className="planNameHeading">Platinum Plan</p>
                                    <div className="wellnessSilverPlanHeadingPrice">
                                        <b><p>&#8377;12499</p></b>
                                        <p> / 12 months</p>
                                    </div>
                                    <div className="directPlansListItemHeadingButtons">
                                        <button id="directPlansListItemButton1"
                                        onMouseEnter={() => setIsHovered5(true)}
                                        onMouseLeave={() => setIsHovered5(false)}
                                        >
                                            Buy Plan
                                            {isHovered5 ? <img src={ Assets.shoppingBagWhite } /> : <img src={ Assets.shoppingBag } /> }
                                        </button>
                                        <button id="directPlansListItemButton2"
                                        onMouseEnter={() => setIsHovered6(true)}
                                        onMouseLeave={() => setIsHovered6(false)}
                                        onClick={ openScheduleCallPopup }
                                        >
                                            Schedule Call
                                            {isHovered6 ? <img src={ Assets.phoneCallWhite } /> : <img src={ Assets.phoneCall } /> }
                                        </button>
                                    </div>
                                </div>
                                <div className="directPlansListContents">
                                    <div className="directPlansListContent1">
                                        <ul>
                                            <li>Differential Diagnosis - One Time - 1 hr</li>
                                            <li>Detailed User Report- <b>Monthly</b></li>
                                            <li><b>Weekly Diet (&) Fitness Plan</b></li>
                                            <li>Created Content Access - Recipe, Fitness, Lifestyle - Weekly</li>
                                            <li>Product Voucher worth Rs. 900 - Free</li>
                                            <li>3 Core Workshop Mental, Physical, Diet</li>
                                        </ul>
                                    </div>
                                    <div className="directPlansListContent2">
                                        <ul>
                                            <li><b>User Progress Report </b> in end of plan</li>
                                            <li><b>Unlimited chat access with coach</b></li>
                                        </ul>
                                            <p className="addOnsText">
                                                Add ons
                                            </p>
                                        <ul style={{ paddingTop:"0" }}>
                                            <li><b>15% Discount on Diagnostic Plan</b></li>
                                            <li><b>20% Discount on Products Purchases</b></li>
                                            <li><b>10% Discount on Products Purchases</b></li>
                                        </ul>
                                    </div>
                                </div>
                            
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default OurPlansDirectPlans;