import { Assets } from '../Assets';

import { Link } from 'react-router-dom';

const MainContentWellnessPlansCheckoutLoginSuccess = () => {

    return ( 
        <div className="laiqaMainContentBg">
            <div className="laiqaMainContentWrapper">
                <div className="laiqaMainContentMidLayer">
                    <img src={ Assets.wellnessPlansCheckoutConfirmBg } id="wellnessPlansBanner1" />
                    {/* <img src={ laiqaMainContentMidLayerResp } id="wellnessPlansBanner2" /> */}
                </div>
            </div>
            <div className="breadcrumbCheckout">
                <div className="breadcrumbWrapper">
                    <div className="checkoutBreadcrumb">
                        <ul>
                            <li><Link to="/">Home </Link></li>
                            <li><Link to="/wellness-plans"> • Wellness Plans • </Link></li>
                            <li id="selectedBreadCrumb"><Link to="/wellness-plans/checkout"> Checkout</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default MainContentWellnessPlansCheckoutLoginSuccess;