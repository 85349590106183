import React,{useState, useEffect} from 'react';
import { Assets } from '../Assets';
import { Link } from 'react-router-dom';
import { PostAPI, GetAPI } from '../utility/APiCall';
function AccountMainContent() {
  return (
   <div className="laiqaMainContentBg">
        <div className="laiqaMainContentWrapper">
            <div className="laiqaMainContentMidLayer">
                <img alt='' src={ Assets.accMainContentBanner } id="wellnessPlansBanner1" />
                <div class="laiqaMainContentHeading DeleteMyAccount"><h1>Delete My Account</h1></div>
            </div>
        </div>
        <div className="breadcrumbBlog">
            <div className="breadcrumbWrapper">
                <div className="blogBreadcrumb" style={{width:'248px'}}>
                    <ul>
                        <li><Link to="/">Home • </Link></li>
                        <li id="selectedBreadCrumb"><Link to="/delete_account"> Delete My Accont</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AccountMainContent
