import React from 'react'
import { useState } from 'react';
import { Assets } from '../Assets'
function AccountDeletedSuccessfullyContent() {
    const [isFocused, setIsFocused] = useState(false);
    const [isFocusedEmail, setIsFocusedEmail] = useState(false);
    const [isChecked, setIsChecked] = useState(true);

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
    };
  return (
      <div className='section-acc'>
          <div className='card-inside'>
              <section className="no-popup">
                  <section className="no-popupInner login_signup">
                      <div className='img-grid-acc'>
                          <img src={Assets.loginPopupBg} alt="Login to Laiqa Wellness" />
                      </div>
                      <div className="popupFormInner">
                          <div className="progressCirclesContainer">
                              <div className=''>
                                  <img style={{ width: '50px' }} src={Assets.checked} alt="checked" />
                                  <h2>Account Deleted Successfully!</h2>
                                  <p style={{ width: '100%', textAlign: 'center', color: '#D27060' }}>You can go back to login or create a new account</p>
                                  <div className="downloadAppPopupContent">
                                      <div className="appScanner">
                                          <img style={{ width: '100px' }} src={Assets.appQr} alt="Periodpal Mobile App" />
                                          <div className="appDownloadTextContent">
                                              <h3 style={{ textAlign: 'left' }}>Download Our App</h3>
                                              <p style={{ textAlign: 'left' }}>Discover the power of our menstrual app designed with you in mind.</p>
                                              <div className="appDownloadLinks">
                                                  <a href="https://apps.apple.com/us/app/period-pal-by-laiqa/id1591718639">
                                                      <img style={{ width: '45%', borderRadius: '0px' }} src={Assets.appStore} alt="Playstore" />
                                                  </a>
                                                  <a href="https://play.google.com/store/apps/details?id=com.laiqa">
                                                      <img style={{ width: '45%', borderRadius: '0px' }} src={Assets.playStore} alt="Playstore" />
                                                  </a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>
              </section>
          </div>
      </div>
  )
}

export default AccountDeletedSuccessfullyContent
