import { Assets } from '../Assets';

import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';

import PopupScheduleCall from '../ScheduleCallPopup/PopupScheduleCall'

const MainContentTeleconsultationPage = () => {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);

    const [showScheduleCallPopup, setShowScheduleCallPopup] = useState(false);

    const openScheduleCallPopup = () => {
        setShowScheduleCallPopup(true);
    };
    
    const closeScheduleCallPopup = () => {
        setShowScheduleCallPopup(false);
    };
    const doctor_list = () =>{
        navigate(`/teleconsultation/select-specialist`)
    }
    return ( 
        <div className="laiqaMainContentBg bookspecialist">
            <PopupScheduleCall show={ showScheduleCallPopup } handleClose={ closeScheduleCallPopup } openLoginOTPPopup={ openScheduleCallPopup } />
            <div className="laiqaMainContentWrapper">
                <div className="laiqaMainContentMidLayer">
                    <img alt='' src={ Assets.teleconsultationMainContentBg } id="teleconsPageBanner1" />
                    <img alt='' src={ Assets.teleconsultationMainContentBgResp } id="teleconsPageBanner2" />
                </div>
                <div className="laiqaMainContentButtons">
                    <button id="midLayerButton"
                        className={ `${isHovered ? 'bookNowTeleconsButtonHovered' : ''}` }
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        onClick={doctor_list}
                        >
                        Let's Start
                        {isHovered ? <img alt='' src={ Assets.arrowUpRight } /> : <img alt='' src={ Assets.arrowUpRightBlack } /> }
                    </button>
                    <div className="scheduleCallMainContent">
                        <div className='scheduleCallButtonWrapper'>
                            <button id="scheduleCallButtonHomePage"
                                className={ `${isHovered2 ? 'bookNowTeleconsButtonHovered' : ''}` }
                                onMouseEnter={() => setIsHovered2(true)}
                                onMouseLeave={() => setIsHovered2(false)}
                                onClick={ openScheduleCallPopup }
                                >
                                {isHovered2 ? <img alt='' src={ Assets.phoneCallingWhite } /> : <img alt='' src={ Assets.phoneCalling } /> }
                            </button>
                        </div>
                        <div className="scheduleCallContent">
                            <p className="scheduleCallp1">Schedule Call</p>
                            <p className="scheduleCallp2">(+91) 987 654 3210</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="breadcrumb">
                <div className="breadcrumbWrapper">
                    <div className="teleconsBreadcrumb">
                        <ul>
                            <li><Link to="/">Home </Link></li>
                            <li><Link to="/"> • Additional Services • </Link></li>
                            <li id="selectedBreadCrumb"><Link to="/teleconsultation"> Teleconsultation</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* <div className="teleconsMainContentBannerBottom">
                <div className="teleconsMainContentBannerBottomWrapper">
                    <img alt='' src={ Assets.teleconsBottomBanner } />
                </div>
            </div> */}
        </div>
     );
}
 
export default MainContentTeleconsultationPage;