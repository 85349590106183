//import { Assets } from "../Assets"
//import { useState } from 'react';
import React,{useState, useEffect} from 'react';
import { Assets } from "../Assets"
import { Link, Navigate } from 'react-router-dom'
import { PostAPI, GetAPI } from '../utility/APiCall';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BlogSectionHomePage = () => {

  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  //const { itemsPerPage, setItemsPerPage } = props;
  const [activePage, setActivePage] = useState(3)
  const [columnSorter, setColumnSorter] = useState(null)
  //const [itemsPerPage, setItemsPerPage] = useState('')
  const [users, setUsers] = useState([])
  const [originalUsersData, setOriginalUsersData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [records, setRecords] = useState([])
  const [visible, setVisible] = useState({ form: false, alert: false });
  const [visibleview, setVisibleview] = useState(false);
  const [pagination, setPagination] = useState({
    activePage: 1,
    columnSorter: null,
    itemsPerPage: 10,
    totalListCount: users.length,
    search: '',
  });
  console.log('pagination',pagination);
  const [error, setError] = useState(false);
  const [flag, setFlag] = useState(false)

  const [resultCat, setresultCat]=useState([]);
  const [resultCatSub, setresultCatSub] = useState([]);

  // list of category
  const [categoryOptions, setCategoryOptions] = useState([]);
  
  const [debouncedSearch, setDebouncedSearch] = useState(pagination.search);
  const settings = {
    //centerMode: true,
   // centerPadding: '15px',
    slidesToShow: 3,
    //autoplay: false,
    //fade:true,
    autoplaySpeed: 6000,
   // speed: 500,
   
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };
  
  const table_list = (callback) => {
        let params = new URLSearchParams();
        pagination?.columnSorter &&
          pagination?.columnSorter.column &&
          params.append(
            'sort',
            `${pagination?.columnSorter.column}%${pagination?.columnSorter.state}`
          );
        pagination?.search && params.append('search', `${pagination?.search}`);
        const url = `/list_blog?page=${pagination?.activePage}&limit=${
          pagination?.itemsPerPage
        }&${params?.toString()}`;
        GetAPI(url, (res) => {
          if (res?.status == 1) {
            setUsers(res?.data);
            const originalData = res?.data;
            setOriginalUsersData(originalData);
            const totalListCount = res?.total;
            console.log('totalListCount',totalListCount);
            setPagination({
              ...pagination,
              totalListCount: res?.total,
            });
            // Call the callback function to indicate completion
          if (callback) {
            callback();
          }
          }
        });
      };
      
      useEffect(() => {
        if (!isFetching) {
          setIsFetching(true);
          // Call table_list with a callback function
          table_list(() => {
            setIsFetching(false);
          });
        }
      }, [flag, pagination.activePage, pagination.columnSorter, pagination.itemsPerPage, pagination.search]);

      useEffect(() => {
        const debounceTimeout = setTimeout(() => {
          setDebouncedSearch(pagination.search);
        }, 230);
        return () => clearTimeout(debounceTimeout);
      }, [pagination.search]);

      //console.log('usersusersBlog', users);

      function formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US'); // Change 'en-US' to your preferred locale
      }

    //pagination props
   const CustomPaginationprops = {
    pagination: pagination,
    setPagination: setPagination,
    onPageChange: (activePage) => setPagination({ ...pagination, activePage }),
    //onPageChange: (activePage) => setPagination({ ...pagination, activePage, activePageChanged: true }),
  };

  console.log('CustomPaginationprops -->',CustomPaginationprops);

  const get_category_list = () =>{
    var url_cat_list = `/list_blog_modules?module=category`;
    GetAPI(url_cat_list, (res)=>{
        const result = res?.data;
        setresultCat(result);
        console.log('result--->',result);
        // Assuming result is an array of objects with parent_id and parent_cat properties
        const uniqueParentIds = [...new Set(result.map(item => item.parent_id))];
        const mappedOptions = uniqueParentIds.map((parent_id) => {
        // Find the corresponding parent_cat based on parent_id
        const item = result.find((item) => item.parent_id === parent_id);
        // Modify as needed
        return {
        parent_id: item.parent_id,
        label: item.parent_cat, // Modify as needed
        value: item.parent_id,  // Modify as needed
        };
    });
    setCategoryOptions(mappedOptions);
    console.log('mappedOptions',mappedOptions);
    })
  }
  
  useEffect(() => {
    get_category_list();
  },[])

  
  const navigateToDetails = (id) => {
    // window.location.href = `/blog/details`;
    navigate(`/blog/details`);
     sessionStorage.setItem('blog_id', id);
 };

 const maxLength = 100; // Maximum length for truncated content

// Function to truncate content if it exceeds maxLength
const truncateContent = (content, maxLength) => {
//return content.length > maxLength ? content.substring(0, maxLength) + '...' : content;
// Truncate content
let truncatedContent = content.length > maxLength ? content.substring(0, maxLength) + '...' : content;
// Remove empty <p> tags at the end
//truncatedContent = truncatedContent.replace(/<\/p>$/, ''); 
truncatedContent = truncatedContent.replace(/<\/?p><br><\/?p>|<\/?p><img[^>]*><\/?p>|<\/?p><span><img[^>]*><\/?p>|<\/?p><span style="color: rgb\(0, 0, 0\);"><img[^>]*><\/?p>/g, ''); 
// Return the modified content
return truncatedContent;
};
    const ImageItem = ({ item}) => {
        const [isHovered, setHovered] = useState(false);
        const [isHovered2, setHovered2] = useState(false);
        return (
         
            <div className='card blshgcars'>
                {/* <img alt="" src={ item.blogImageSrc } /> */}
                {item.thumbnail ? (
                <img alt={item.title} className="card-img-top" src={item.thumbnail} />
                ) : (
                <img alt="Default" className="card-img-top" src={Assets.vectorDefault} />
                )}
                <p className="blogContentItemsHeading">{ item.title }</p>
                <p className="blogContentItemsContent" 
                dangerouslySetInnerHTML={{
                __html: truncateContent(item.blog_content),
                }}
                ></p>
                <button onClick={() => navigateToDetails(item.id)} id="blogContentReadMoreButton" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                    Read More
                    <img alt="" src={ isHovered ? Assets.arrowUpRight : Assets.arrowUpRightPink } />
                </button>
            </div>
           
        );
    }
    return ( 
        <div className="blogContent">
            <div className="blogContentWrapper">
                <div className="blogContentWrapperInner">
                    <div className="blogHeadingAndLinkContent">
                        <div className="blogContentHeading">
                            <p className="blogContentHeading1">Blogs</p>
                            <p className="blogContentHeading2">See Latest News</p>
                            <p className="blogContentHeading3">from the Blog Posts</p>
                        </div>
                        <div className="blogContentList">
                        
                          <Link to={'/blog'}><button>Read More</button></Link>
                        </div>
                    </div>
                    <ul className="blogContentItems_">
                      <Slider {...settings}>
                      {users?.slice(0, 10)?.map((item) => (
                      <ImageItem key={item.id} item={item} />
                      ))}
                      </Slider>                 
                       </ul>
                    <div className="blogContentBottomBannerWrapper">
                        <div className="blogContentBottomBanner">
                            <img alt="" src={ Assets.blogContentBanner } id="blogContentBottomBanner1" />
                            <img alt="" src={ Assets.blogContentBannerResp } id="blogContentBottomBanner2" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default BlogSectionHomePage;