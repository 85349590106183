import { Assets } from "../Assets"

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
// import ContentFrameKyw13 from "../Kyw13/ContentFrameKyw13";
import NavbarKyw from "../Home/NavbarHomePage"
//import { useHistory } from 'react-router-dom';

//const ContentFrameKyw11 = () => {

function ContentFrameKyw11(props) {

    const navigate = useNavigate();
    // Inside your component function
    // const history = useHistory();
    const {data, setData, errors, setErrors, questionId = [], handleRadioChange, inputValue, 
        getSleepData, setSleepData, message, setMessage, sliderValue, handleFormSubmit, getSleepscaleData, 
        setSleepscaleData, isChecked, setIsChecked, formSubmitted, setFormSubmitted } = props;

    const [isHovered, setIsHovered] = useState(false);

    console.log('formSubmitted sleep>>',formSubmitted);

    const handleSubmit = (e) => {
      e.preventDefault(); // Prevents the default form submission behavior

      // If all questions are answered, proceed with form submission
      setShowLoginPopup(true); // Set showLoginPopup to true
      setFormSubmitted(true); // Update the state to mark form as submitted
      props.handleFormSubmit(e); // Call the parent's form submission function
      //alert('check');
      //navigate('/selfprognosis/know-your-wellness/sleep-assessment-result');
  };

    const clickNext = (e) => {
        e.preventDefault(); // Prevents the default form submission behavior
        navigate('/selfprognosis/know-your-wellness/stress');
        //navigate('/selfprognosis/know-your-wellness/sleep-assessment-result');
    }

    const [visible, setVisible] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const [showLoginPopup, setShowLoginPopup] = useState(false);

    const handleClose = () => {
        setIsOpen(false);
    };

    const openLoginPopup = () => {
      //  setShowLoginPopup(true);
        //alert('Check');
        setFormSubmitted(true); // Update the state to mark form as submitted
    };
    
    const closeLoginPopup = () => {
        setShowLoginPopup(false);
    };

    //const showHideClassName2 = show ? 'loginPopupBg display-block' : 'loginPopupBg display-none';
    const showHideClassName2 = showLoginPopup
    ? 'loginPopupBg result display-block'
    : 'loginPopupBg result display-none';

    const user_name = sessionStorage.getItem("user_name");

    const getBmiCategoryColor = () => {
        if (getSleepData < 8) {
            return '#D27060';
        } else {
            return '#7AB375';
        }
    };

    const bmiCategoryColor = getBmiCategoryColor();

    const [currentStep, setCurrentStep] = useState(1);
    //const [errors, setErrors] = useState({});

    const nextStep = () => {
      const steps = {
          1: ["19", "20", "21"],
          2: ["22", "23"]
      };

      const unansweredQuestions = questionId
          .filter(item => steps[currentStep].includes(item.question_id))
          .filter(item => !data.answer.some(ans => ans.question_id === item.question_id));

      if (unansweredQuestions.length > 0) {
          const newErrors = {};
          unansweredQuestions.forEach(item => {
              newErrors[item.question_id] = "Please choose at least one.";
          });
          props.setErrors(newErrors);
          return;
      }

      props.setErrors({});
      setCurrentStep(prev => Math.min(prev + 1, Object.keys(steps).length));
  };

    const prevStep = () => {
        setCurrentStep(prev => Math.max(prev - 1, 1));
    };

    const renderQuestions = () => {
        if (!questionId || !Array.isArray(questionId)) {
            return <div>No questions available</div>;
        }

        let questionsToRender;
        if (currentStep === 1) {
            questionsToRender = questionId.filter(item => ["19", "20", "21"].includes(item.question_id));
        } else if (currentStep === 2) {
            questionsToRender = questionId.filter(item => ["22", "23"].includes(item.question_id));
        }

        return questionsToRender.map((item, index) => {
            let filteredOptions = [];

            if (item.question_id === "19") {
                filteredOptions = item.options.map(option => {
                    const optionText = option.option.toLowerCase();
                    if (optionText.includes('not much')) {
                        return { ...option, option: 'Not much' };
                    } else if (optionText.includes('moderately')) {
                        return { ...option, option: 'Moderately' };
                    } else if (optionText.includes('usually')) {
                        return { ...option, option: 'Usually' };
                    }
                    return null;
                }).filter(option => option !== null);
            } else if (item.question_id === "21") {
                filteredOptions = item.options.map(option => {
                    const optionText = option.option.toLowerCase();
                    if (optionText.includes('yes')) {
                        return { ...option, option: 'Never' };
                    } else if (optionText.includes('sometimes')) {
                        return { ...option, option: 'Sometimes' };
                    } else if (optionText.includes('no')) {
                        return { ...option, option: 'Always' };
                    }
                    return null;
                }).filter(option => option !== null);
            } else if (["20", "22", "23"].includes(item.question_id)) {
                filteredOptions = item.options.map(option => {
                    const optionText = option.option.toLowerCase();
                    if (optionText.includes('never')) {
                        return { ...option, option: 'Never' };
                    } else if (optionText.includes('sometimes')) {
                        return { ...option, option: 'Sometimes' };
                    } else if (optionText.includes('always')) {
                        return { ...option, option: 'Always' };
                    }
                    return null;
                }).filter(option => option !== null);
            } else {
                filteredOptions = item.options;
            }

            return (
                <div key={index} className="kyw1MainContentHeading">
                    <h1 style={{ marginTop: '0px' }}>{item.question}</h1>
                    {filteredOptions.map((option, j) => (
                        <div key={j} className="flex items-center mr-4 mb-4 radio">
                            <input
                                id={`${item.question_id}_${option.id}`}
                                value={option.id}
                                type="radio"
                                onChange={() => handleRadioChange(item.question_id, option.id)}
                                //checked={answers[item.question_id] === option.id}
                                checked={inputValue[item.question_id] === option.id}
                                name={`radio${item.question_id}`}
                                className="hidden" />
                            <label htmlFor={`${item.question_id}_${option.id}`} className="flex items-center cursor-pointer">
                                <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                                {option.option}
                            </label>
                        </div>
                    ))}
                    {errors[item.question_id] && <div className="error">{errors[item.question_id]}</div>}
                </div>
            );
        });
    };
    
    return (
      <div className="contentFrameKyw">
        <div className="contentFrameKywInner">
          <div className="kyw1BgImage position-relative">
            <div className="absoluteStage">
              <p className="mb-0" style={{ marginBottom: "0px" }}>
                Step 3
              </p>
              <p>Sleep</p>
            </div>
            <img src={Assets.kyw11Bg} alt="Sleep" />
            <div className="absolute">
              <h4>
                Have you noted changes in your sleep pattern before, during or
                after periods?
              </h4>
              <p>
                If you want to improve your lifestyle by regularizing your
                sleep, let’s do it.
              </p>
            </div>
          </div>
          <div className="vertProgressLine"></div>
          <div className="vertProgressLine2"></div>
          <div className="vertProgressLine3"></div>
          <div className="progressCirclesContainer">
            <div className="progressCirclesOuter1">
              <div className="currentProgressCircleInner">
                <div className="progressCirclesId">
                  <div className="progressCircleCompletedd"></div>
                </div>
              </div>
            </div>
            <svg
              id="progressCircleSvg1"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="75px"
              height="75px"
            >
              <circle
                className="progressCircleCompleted"
                cx="41"
                cy="34.5"
                r="32"
                strokeLinecap="round"
              />
            </svg>
            <div className="progressCirclesOuter2">
              <div className="currentProgressCircleInner">
                <div className="progressCirclesId">
                  {" "}
                  <div className="progressCircleCompletedd"></div>{" "}
                </div>
              </div>
            </div>
            <svg
              id="progressCircleSvg2"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="75px"
              height="75px"
            >
              <circle
                className="progressCircleCompleted"
                cx="41"
                cy="34.5"
                r="32"
                strokeLinecap="round"
              />
            </svg>
            <div className="progressCirclesOuter3">
              <div className="currentProgressCircleInner">
                <div className="progressCirclesId"> 3 </div>
              </div>
            </div>
            <svg
              id="progressCircleSvg3"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="75px"
              height="75px"
            >
              <circle
                className="progressCircleOneThird"
                cx="41"
                cy="34.5"
                r="32"
                strokeLinecap="round"
              />
            </svg>
            <div className="progressCirclesOuter4">
              <div className="progressCirclesInner">
                <div className="progressCirclesId"> 4 </div>
              </div>
            </div>
            <div className="progressCirclesOuter5">
              <div className="progressCirclesInner">
                <div className="progressCirclesId"> 5 </div>
              </div>
            </div>
            <div className="progressCirclesOuter6">
              <div className="progressCirclesInner">
                <div className="progressCirclesId"> 6 </div>
              </div>
            </div>
            <div className="progressCirclesOuter7">
              <div className="progressCirclesInner">
                <div className="progressCirclesId"> 7 </div>
              </div>
            </div>
          </div>
          <div className="kyw6MainContent kyw1MainContent">
          {formSubmitted ? (
                        <>
            <div className="kyw4MainContentHeading">
                      <p>Hey {user_name},</p>
                    </div>
                    <div className="kyw4MainContentSubHeading">
                      <p>Thanks for taking the Sleep test.</p>
                    </div>
                    <div className="bmiResultWrapper">
                      <div className="bmiResultHeading">
                        <p>Based on your response, your score is </p>
                      </div>
                      <div className="bmiResult">
                        <div className="bmiResultFloatResponse">
                          <input
                            type="text"
                            autocomplete="off"
                            id="bmiHeightInputId"
                            value={getSleepData}
                            maxLength={4}
                          />
                        </div>
                        <div className="bmiResultStringResponse">
                          <div
                            style={{
                              marginTop: "10px",
                              color: bmiCategoryColor,
                            }}
                          >
                            {bmiCategoryColor === "#D27060" && "Poor Sleep"}
                            {bmiCategoryColor === "#7AB375" && "Good Sleep"}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="sleepSliderContainer">
                          <input
                            type="range"
                            id="slider"
                            className="bmiSlider"
                            name="slider"
                            min="0"
                            max="12"
                            value={getSleepData}
                            // onChange={ handleSliderChange }
                          />
                        </div>
                      </div>
                      <div className="bmiLogoBr">
                        <img alt="" src={Assets.sleepLogo} />
                      </div>
                    </div>
                    <div className="bmiCategories">
                      <ul>
                        {getSleepscaleData &&
                          getSleepscaleData.map((item) => (
                            <li
                              className={item.selected ? "selected" : ""}
                              key={item.id}
                            >
                              {item.description}
                            </li>
                          ))}
                      </ul>
                    </div>
                    <div className="kywNextLinkWrapper">
                      <Link
                        className="backLink"
                        to=""
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        <img src={Assets.backArrow} alt="Back" />
                        Back
                      </Link>
                      <button
                        type="button"
                        onClick={clickNext}
                        id="kywNextButtonSmall"
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                      >
                        Next
                        {isHovered ? (
                          <img src={Assets.arrowUpRight} />
                        ) : (
                          <img src={Assets.arrowUpRightBlack} />
                        )}
                      </button>
                    </div>
						  </>
                  ) : (
                    <>
                    <form onSubmit={handleSubmit}>
              {renderQuestions()}
              <div className="kywNextLinkWrapper">
                <Link className="backLink" to="">
                  <img src={Assets.backArrow} alt="Back" />
                  Back
                </Link>
                {currentStep === 1 && (
                    <button
                      type="button"
                      onClick={nextStep}
                      id="kywNextButtonSmall"
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                  >
                    Next
                    {isHovered ? (
                      <img alt="" src={Assets.arrowUpRight} />
                    ) : (
                      <img alt="" src={Assets.arrowUpRightBlack} />
                    )}
                  </button>
                )}
                {currentStep === 2 && (
                  <button
                    type="submit"
                    id="kywNextButtonSmall"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    //onClick={openLoginPopup}
                  >
                    Next
                    {isHovered ? (
                      <img alt="" src={Assets.arrowUpRight} />
                    ) : (
                      <img alt="" src={Assets.arrowUpRightBlack} />
                    )}
                  </button>
                )}
              </div>
            </form>
					</>
                )}
            
            {/* <ContentFrameKyw13 getSleepData={getSleepData} getSleepscaleData={getSleepscaleData}/> */}
          </div>
        </div>
      </div>
    );
}

export default ContentFrameKyw11;