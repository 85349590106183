import { Assets } from '../Assets';

import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom'

const SelectedPlansWellnessPlansCheckoutLoginOTP = () =>{

    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [isOtpComplete, setIsOtpComplete] = useState(false);
    const otpInputs = Array.from({ length: 6 }, (_, index) => index);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const inputRefs = otpInputs.map(() => useRef(null));

    useEffect(() => {
        inputRefs[0].current.focus();
    }, []);
    
    const handleChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        if (value !== '' && index < otpInputs.length - 1) {
          inputRefs[index + 1].current.focus();
        }
        const isComplete = newOtp.every((char) => char !== '');
        setIsOtpComplete(isComplete);
    };
    
    const handleBackspace = (index) => {
        if (otp[index] === '') {
            if (index > 0) {
              inputRefs[index - 1].current.focus();
            }
        }
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);

        const isComplete = newOtp.every((char) => char !== '');
        setIsOtpComplete(isComplete);
    };

    return(
        <div className="selectedPlanWrapper">
            <div className="plansAndLoginWrapper">

                <div className="selectedPlansPriceList">
                    <div className="checkoutPriceListWrapper">
                        <Link to="/wellness-plans/checkout">
                            <div className="checkoutSilverPlan">
                                <div className="checkoutPlanHeading" id="silverPlanHeading">
                                    <p className="planNameHeading">Silver Plan</p>
                                    <div className="wellnessSilverPlanHeadingPrice">
                                        <b><p>&#8377;3999</p></b>
                                        <p> /month</p>
                                    </div>
                                </div>
                                <div className="checkoutPlanListContent">
                                    <ul>
                                        <li>Monthly Lifestyle and Menstrual Assessment</li>
                                        <li>Weekly Diet & Fitness Plans</li>
                                        <li>Daily Lifestyle Tips</li>
                                        <li>Unlimited Chat Support</li>
                                        <li>Fortnightly Consultation with Ayurvedic Doctor / Dietician</li>
                                        <li>Weekly Expert Workshops</li>
                                        <li>Free Product Voucher Worth of ₹900</li>
                                        <li>User Progress Report at the end of plan</li>
                                    </ul>
                                </div>
                            </div>
                        </Link>

                        <Link to="/wellness-plans/checkout">
                            <div className="checkoutGoldPlan">
                                <div className="checkoutPlanHeading" id="goldPlanHeading">
                                    <p className="planNameHeading">Gold Plan</p>
                                    <div className="wellnessSilverPlanHeadingPrice">
                                        <b><p>&#8377;7499</p></b>
                                        <p> /month</p>
                                    </div>
                                </div>
                                <div className="checkoutPlanListContent">
                                    <ul>
                                        <li>Monthly Lifestyle and Menstrual Assessment</li>
                                        <li>Weekly Diet & Fitness Plans</li>
                                        <li>Daily Lifestyle Tips</li>
                                        <li>Unlimited Chat Support</li>
                                        <li>Fortnightly Consultation with Ayurvedic Doctor / Dietician</li>
                                        <li>Weekly Expert Workshops</li>
                                        <li>Free Product Voucher Worth of ₹1800</li>
                                        <li>User Progress Report at the end of plan</li>
                                    </ul>
                                </div>
                                
                            </div>
                        </Link>

                        <Link to="/wellness-plans/checkout">
                            <div className="checkoutPlatinumPlan">
                                <div className="checkoutPlanHeading" id="platinumPlanHeading">
                                    <p className="planNameHeading">Platinum Plan</p>
                                    <div className="wellnessSilverPlanHeadingPrice">
                                        <b><p>&#8377;12499</p></b>
                                        <p> /month</p>
                                    </div>
                                </div>
                                <div className="checkoutPlanListContent">
                                    <ul>
                                    <li>Monthly Lifestyle and Menstrual Assessment</li>
                                        <li>Weekly Diet & Fitness Plans</li>
                                        <li>Daily Lifestyle Tips</li>
                                        <li>Unlimited Chat Support</li>
                                        <li>Fortnightly Consultation with Ayurvedic Doctor / Dietician</li>
                                        <li>Weekly Expert Workshops</li>
                                        <li>Free Product Voucher Worth of ₹3600</li>
                                        <li>User Progress Report at the end of plan</li>
                                    </ul>
                                </div>
                            
                            </div>
                        </Link>

                    </div>
                </div>

                <div className="checkoutLogin">
                    <div className="checkoutLoginWrapper">
                        <div className="checkoutLoginHeading2">
                            Login
                        </div>
                        <div className="checkoutLoginContent">
                            <div className="checkoutLoginPhoneNumberHeading">
                                Verify your Number
                            </div>
                            <div className="checkoutLoginPhoneNumberHeading2">
                                +91 *******3210 - <Link to="/wellness-plans/checkout/login">Change</Link>
                            </div>
                            <div className="otpFormWrapper">
                                <form action="">
                                    {otpInputs.map((index) => (
                                        <input
                                        key={index}
                                        type="text"
                                        maxLength="1"
                                        value={otp[index]}
                                        onChange={(e) => handleChange(index, e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Backspace') {
                                            handleBackspace(index);
                                            }
                                        }}
                                        ref={inputRefs[index]}
                                        />
                                    ))}
                                </form>
                                <Link id="couponSubmitLink" to="/wellness-plans/checkout/login/success">
                                    <button id="otpSubmit"
                                        className={isOtpComplete ? 'enabled-otp-submit-button' : 'disabled-otp-submit-button'}
                                        disabled={otp.join('').length < 6}
                                    >
                                        Submit
                                    </button>
                                </Link>
                            </div>
                            <div className="termsAndConditionsText">
                                By continuing, you agree to the <br /> <b>terms & conditions</b> and <b>privacy policy</b> of Laiqa
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="complianceContentCheckout">
                    <div className="complianceContentWrapper">
                        <div className="complianceContentHeading">
                            <div className="complianceContentHeading1">
                                Safe and Secure
                            </div>
                            <div className="complianceContentHeading2">
                                We are compliant to <b>Industry standards.</b>
                            </div>
                        </div>
                        <div className="complianceContentImage">
                            <img src={ Assets.complianceContentBanner } alt="Industry Standards" />
                        </div>
                    </div>
                </div>

        </div>
    )
}

export default SelectedPlansWellnessPlansCheckoutLoginOTP;