import { Assets } from "../Assets"

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const ContentFrameKyw6 = () => {

    const navigate = useNavigate();

    const [isHovered, setIsHovered] = useState(false);
    const [sliderValue2, setSliderValue2] = useState(1);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOption2, setSelectedOption2] = useState(null);

    const handleOptionClick = (option) => {
      setSelectedOption(option);
    };

    const handleOptionClick2 = (option) => {
        setSelectedOption2(option);
      };

      const handleSliderChange2 = (event) => {
        const newValue = parseInt(event.target.value, 10);
        setSliderValue2(newValue);
      };
    
    return (
        <div className="contentFrameKyw">
            <div className="contentFrameKywInner">
                <div className="kyw1BgImage">
                    <img src={ Assets.painActivityBg } alt="Body Mass Index" />
                </div>
                <div className="vertProgressLine"></div>
                <div className="vertProgressLine2"></div>
                <div className="vertProgressLine3"></div>

                <div className="progressCirclesContainer">

                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> 1 </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg1" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>


                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> 2 </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg2" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleCompleted" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>

                    <div className="progressCirclesOuter1">
                        <div className="currentProgressCircleInner">
                            <div className="progressCirclesId"> 3 </div>
                        </div>
                    </div>
                    <svg id="progressCircleSvg3" xmlns="http://www.w3.org/2000/svg" version="1.1" width="75px" height="75px">
                        <circle className="progressCircleTwoThird" cx="41" cy="34.5" r="32" strokeLinecap="round" />
                    </svg>

                    <div className="progressCirclesOuter4">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 4 </div>
                        </div>
                    </div>

                    <div className="progressCirclesOuter5">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 5 </div>
                        </div>
                    </div>

                    <div className="progressCirclesOuter6">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 6 </div>
                        </div>
                    </div>

                    <div className="progressCirclesOuter7">
                        <div className="progressCirclesInner">
                            <div className="progressCirclesId"> 7 </div>
                        </div>
                    </div>
                </div>
                <div className="kyw6MainContent">
                    <div className="kyw1MainContentHeading">
                        <h1>Where do you feel discomfort during your period? Please specify the areas of your body where you experience pain</h1>
                    </div>
                    <div className="painOptionCategories">
                        <ul>
                            <li
                                onClick={() => handleOptionClick('Abdomen')}
                                className={ selectedOption === 'Abdomen' ? 'selectedPainOption' : '' }
                            >Abdomen</li>
                            <li
                                onClick={() => handleOptionClick('Lower Back')}
                                className={ selectedOption === 'Lower Back' ? 'selectedPainOption' : '' }
                            >Lower Back</li>                            
                            <li
                                onClick={() => handleOptionClick('Legs')}
                                className={ selectedOption === 'Legs' ? 'selectedPainOption' : '' }
                            >Legs</li>
                            <li
                                onClick={() => handleOptionClick('Groin Area')}
                                className={ selectedOption === 'Groin Area' ? 'selectedPainOption' : '' }
                            >Groin Area</li>
                        </ul>
                    </div>

                    <div className="kyw1MainContentHeading">
                        <h1>On a scale from mild to severe, how would you describe the intensity of your pain during periods?</h1>
                    </div>
                    <div className="kyw3MainContentItems">
                        <div className={`kywSlideContainer option-${ sliderValue2 }`}>
                            <input
                                type="range"
                                className="painSlider"
                                name="slider"
                                min="0"
                                max="2"
                                value={ sliderValue2 }
                                onChange={ handleSliderChange2 }
                            />
                            <div className="sliderLabelContent">
                                <label htmlFor="sliderHeight" style={{ marginLeft: "10px" }}>Mild</label>
                                <label htmlFor="sliderHeight" style={{ marginLeft: "10px" }}>Moderate</label>
                                <label htmlFor="sliderHeight">Severe</label>
                            </div>
                        </div>
                    </div>
 
                    <div className="kyw1MainContentHeading">
                        <h1>What is the average duration of the pain?</h1>
                    </div>
                    <div className="painOptionCategories">
                        <ul>
                            <li
                                onClick={() => handleOptionClick2('1-2 Days')}
                                className={ selectedOption2 === '1-2 Days' ? 'selectedPainOption' : '' }
                            >1-2 Days</li>
                            <li
                                onClick={() => handleOptionClick2('3-4 Days')}
                                className={ selectedOption2 === '3-4 Days' ? 'selectedPainOption' : '' }
                            >3-4 Days</li>                            
                            <li
                                onClick={() => handleOptionClick2('5 Days or more')}
                                className={ selectedOption2 === '5 Days or more' ? 'selectedPainOption' : '' }
                            >5 Days or more</li>
                        </ul>
                    </div>

                    <div className="kywNextLinkWrapper">
                    {/* <Link className="backLink" to="/selfprognosis/know-your-wellness/pain-assessment"></Link> */}
                        <Link className="backLink" to="" onClick={() => {
                    navigate(-1);
                    }}>
                            <img src={ Assets.backArrow } alt="Back" />
                            Back
                        </Link>
                        <Link to="/selfprognosis/know-your-wellness/pain-assessment-result">
                            <button id="kywNextButtonSmall"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                >
                                Next
                                {isHovered ? <img src={ Assets.arrowUpRight } /> : <img src={ Assets.arrowUpRightBlack } /> }
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ContentFrameKyw6;