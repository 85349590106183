import { Assets } from '../Assets';

import { useState } from 'react';
import { Link } from 'react-router-dom';

import PopupScheduleCall from '../ScheduleCallPopup/PopupScheduleCall'

const OurDoctorsTeleconsultation = () =>{

    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);

    const [showScheduleCallPopup, setShowScheduleCallPopup] = useState(false);

    const openScheduleCallPopup = () => {
        setShowScheduleCallPopup(true);
    };
    
    const closeScheduleCallPopup = () => {
        setShowScheduleCallPopup(false);
    };

    return(
        <div className="ourDoctorsTeleconsWrapperOuter">
            <PopupScheduleCall show={ showScheduleCallPopup } handleClose={ closeScheduleCallPopup } openLoginOTPPopup={ openScheduleCallPopup } />
            <div className="ourDoctorsTeleconsWrapper">
                <div className="ourDoctorsTeleconsHeadingAndContent">
                    <div className="areaOfConcernContentHeading">
                        <p className="areaOfConcernContentHeading1">Our Team</p>
                        <p className="areaOfConcernContentHeading2">Meet Our Dedicated Team Of</p>
                        <p className="areaOfConcernContentHeading3">Healthcare Professionals</p>
                    </div>
                    <div className="ourDoctorsTeleconsContent">
                        <div className="ourDoctorsTeleconsArticle">
                            {/* <p>At the heart of our online consultation is a diverse team of experienced and compassionate medical professionals. Each doctor is meticulously selected for their expertise, dedication to patient care, and commitment to staying abreast of the latest advancements in their respective fields. Our platform connects you with specialists from various disciplines, ensuring you receive comprehensive, personalized care. Trust, empathy, and excellence define our service, as we strive to make your healthcare journey both effective and reassuring.</p> */}
                            <p>At the heart of our online consultation is a diverse team of experienced and compassionate medical professionals. Each doctor is meticulously selected for their expertise, dedication to patient care, and commitment to staying abreast of the latest advancements in their respective fields. Our platform connects you with specialists from various disciplines, ensuring you receive comprehensive, personalized care. Trust, empathy, and excellence define our service, as westrive to make your healthcare journey both effective and reassuring.</p>
                        </div>
                        <div className="ourDoctorsTeleconsButtons">
                            <Link to="/teleconsultation/select-specialist" style={{ textDecoration: "none" }}>
                                <button id="bookNowTeleconsButton"
                                    className={ `${isHovered ? 'bookNowTeleconsButtonHovered' : ''}` }
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                    >
                                    Book now
                                    {isHovered ? <img src={ Assets.arrowUpRight } /> : <img src={ Assets.arrowUpRightBlack } /> }
                                </button>                            
                            </Link>
                            <div className="scheduleCall">
                                <div className='scheduleCallButtonWrapper'>
                                    <button id="scheduleCallButton"
                                        className={ `${isHovered2 ? 'bookNowTeleconsButtonHovered' : ''}` }
                                        onMouseEnter={() => setIsHovered2(true)}
                                        onMouseLeave={() => setIsHovered2(false)}
                                        onClick={ openScheduleCallPopup }
                                        >
                                        {isHovered2 ? <img src={ Assets.phoneCallingWhite } /> : <img src={ Assets.phoneCalling } /> }
                                    </button>
                                </div>
                                <div className="scheduleCallContent">
                                    <p className="scheduleCallp1">Schedule Call</p>
                                    <p className="scheduleCallp2">(+91) 987 654 3210</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ourDoctorsImageWrapper">
                    <img alt='' src={ Assets.ourDoctorsMainDoctorImg } />
                </div>
            </div>
        </div>
    )
}

export default OurDoctorsTeleconsultation;