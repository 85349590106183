import React from "react";
import { Assets } from '../Assets';
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useState } from 'react';
import { useNavigate } from "react-router-dom";

const WcuWellnessPlansMenstrualWellbeing = () => {

    const navigate = useNavigate();
    const testimonials_content = [
        {
            id:1,
            name:'Simran',
            description:"Joining Laiqa was the best choice I made. Their unique approach to women's wellness has completely changed my daily life. I'm confident that if I continue to follow Laiqa's advice, I'll keep experiencing these positive changes. Thank you, Laiqa, for all your support."
        },
        {
            id:2,
            name:'Shivani',
            description:"Laiqa's recommendations are truly amazing and seamlessly integrate into my daily routine. My PMS symptoms and menstrual flow have both shown remarkable improvement. I wholeheartedly recommend joining Laiqa. #FollowLaiqa. Thank you to the Laiqa team for their fantastic support!"
        },
        {
            id:3,
            name:'Riya',
            description:"Laiqa's recommendations have truly made a positive impact on my life. They've effectively helped me manage my PCOS symptoms, and I've experienced a significant improvement in my menstrual flow.I want to express my sincere gratitude to the Laiqa team."
        },
        {
            id:4,
            name:'Neha',
            description:"I can't thank the Laiqa team enough for their amazing support. Their approach to improving women's health has truly changed my life. After struggling with irregular periods for months, I followed their advice wholeheartedly.I'm so happy to say that, in a short time, my periods came back with a good flow. I'm confident that by sticking to Laiqa's recommendations, I'll have regular periods. Laiqa, you've made a real difference in my life!"
        }
    ];

    const settings = {
        centerMode: true,
        centerPadding: '10px',
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 6000,
       // speed: 500,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              slidesToShow: 1,
            },
          },
        ],
      };

    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const doctor_list = () =>{
        navigate(`/teleconsultation/select-specialist`)
    }
    return(
        <div className="whyChooseUsMenstrualWellbeingWrapper why_us">
            <div className="whyChooseUsMenstrualWellbeing">
                <div className="whyChooseUsWrapper">
                    <div className="whyChooseUsStats">
                        <div className="wcuStatsWrapper">
                            {/* <img src={ Assets.wcuStats } alt="Why Choose Us" /> */}
                                <div className="grid_cnvt_content">
                                <div className="grid_bx">
                                <h2>1 <span> lakh+</span></h2>
                                <p>Women trusted us</p>
                                </div>
                                <div className="grid_bx">
                                <h2>4.7/5</h2>
                                <p>Rating across Services</p>
                                </div>
                                <div className="grid_bx">
                                <h2>90+</h2>
                                <p>Parameters</p>
                                </div>
                                </div>
                            <div className="wcuStatsButtonWrapper">
                                <button id="wcuStatsButton" 
                                    className={`${isHovered ? 'wcsStatsButtonHovered' : ''}`}
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                    onClick={doctor_list()}
                                    >
                                    <p>Let's Start</p>
                                    {isHovered ? <img src={ Assets.arrowUpRight } /> : <img src={ Assets.arrowUpBlack } />}
                                </button>
                            </div>
                            <div className="wcuContactUsWrapper">
                                <button id="wcuContactUsButton"
                                    className={`${isHovered ? 'wcsStatsButtonHovered' : ''}`}
                                    onMouseEnter={() => setIsHovered2(true)}
                                    onMouseLeave={() => setIsHovered2(false)}
                                    >
                                    <p>Contact Us</p>
                                    {isHovered2 ? <img src={ Assets.phoneCallWhite } /> : <img src={ Assets.phoneCall } />}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="whyChooseUsContent">
                        <div className="whyChooseUsTextContent">
                            <div className="whyChooseUsHeading">
                                <p className="wcuHeading1">Why us?</p>
                                <p className="wcuHeading2">We settle for nothing but the best for you! Embark on an unforgettable wellness journey with Laiqa.</p>
                            </div>
                        </div>
                        <div className="whyChooseUsList">
                            {/* <img src={ Assets.wcuList } /> */}
                            <div className="parent_why">
                      <div className="child_why">
                        <div className="grid_content">
                          <img alt="" src={Assets.Rectangle_15619} />
                           <div className="contentbx">
                           <h4>For Corporates</h4>
                           <p> Interactive workshops, team-based fitness challenges, mindfulness and breathwork sessions </p>
                           </div>
                         </div>
                         <div className="grid_content">
                           <img alt="" src={Assets.Rectangle_15620} />
                            <div className="contentbx">
                            <h4>For Employees</h4>
                            <p> Personalized nutrition plans and dietary advice, workshops  to address employee well-being, and counselling sessions. </p>
                            </div>
                         </div>
                         <div className="grid_content">
                         <img alt="" src={Assets.Rectangle_15621} /> 
                           <div className="contentbx">
                           <h4>For Leaders</h4>
                           <p> Peer interactions, fostering healthier workplace environments, and enhancing leadership effectiveness via self-reflection and skill-building activities. </p>
                           </div>
                         </div>
                        </div>
                      </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="complianceContent">
                <div className="complianceContentWrapper">
                    <div className="complianceContentHeading">
                        <div className="complianceContentHeading1">
                            Safe and Secure
                        </div>
                        <div className="complianceContentHeading2">
                            We are compliant to <b>Industry standards.</b>
                        </div>
                    </div>
                    <div className="complianceContentImage">
                        <img src={ Assets.complianceContentBanner } alt="Industry Standards" />
                    </div>
                </div>
            </div>

            <div className="feedbackContentTelecons">
                <div className="feedbackContentWrapper">
                    <div className="feedbackContentImageWrapper">
                        <img src={ Assets.feedbackContentImage } />
                    </div>
                    <div className="feedbackContentTextWrapper">
                        <div className="feedbackContentHeading1">
                            <p>TESTIMONIAL</p>
                        </div>
                        <div className="feedbackContentHeading2">
                            <p>What People Say</p>
                        </div>
                        <div className="feedbackContentHeading3">
                            <p>About Laiqa</p>
                        </div>
                        <div className="feedbackMessageContent">
                            <div className="feedbackRatingContent">
                                <img className="feedbackRatingContentQuotes" src={ Assets.feedbackQuotes } />
                                <img className="feedbackRatingContentStars" src={ Assets.feedbackRatingStars } />
                            </div>
                            <div className="feedbackMessage">
                            <Slider {...settings}>
                                {testimonials_content.map((item, i) => (
                                    <div key={i}>
                                    <div className="testimonials_card">
                                    <p>{item.description}</p>
                                    <h5 className="font-italic">{item.name}</h5>
                                    </div>
                                    </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        
    )
}

export default WcuWellnessPlansMenstrualWellbeing;