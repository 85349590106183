import React,{useEffect, useState, useContext } from 'react';
import { BrowserRouter, Switch, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Components/Home';
import Teleconsultation from './Components/Teleconsultation';
import TeleconsultationSelectDoctor from './Components/TeleconsultationSelectDoctor';
import TeleconsultationConfirmBooking from './Components/TeleconsultationConfirmBooking';
import WellnessPlans from './Components/WellnessPlans';
import WellnessPlansMenstrualWellbeing from './Components/WellnessPlansMenstrualWellbeing';
import WellnessPlansCheckout from './Components/WellnessPlansCheckout';
import WellnessPlansCheckoutLogin from './Components/WellnessPlansCheckoutLogin';
import WellessPlansCheckoutLoginOtp from './Components/WellnessPlansCheckoutLoginOTP';
import WellnessPlansCheckoutLoginSuccess from './Components/WellnessPlanCheckoutLoginSuccess';
import Corporate from './Components/Corporate';
import Workshop from './Components/Workshop';
import DirectPlans from './Components/DirectPlans';
import SelfPrognosis from './Components/SelfPrognosis';
import KnowYourWellness1 from './Components/KnowYourWellness1';
import KnowYourWellness2 from './Components/KnowYourWellness2';
import KnowYourWellness3 from './Components/KnowYourWellness3';
import KnowYourWellness5 from './Components/KnowYourWellness5';
import KnowYourWellness6 from './Components/KnowYourWellness6';
import KnowYourWellness8 from './Components/KnowYourWellness8';
import KnowYourWellness9 from './Components/KnowYourWellness9';
import KnowYourWellness11 from './Components/KnowYourWellness11';
import KnowYourWellness12 from './Components/KnowYourWellness12';
import KnowYourWellness14 from './Components/KnowYourWellness14';
import KnowYourWellness15 from './Components/KnowYourWellness15';
import KnowYourWellness20 from './Components/KnowYourWellness20';
import KnowYourWellness24 from './Components/KnowYourWellness24';
// import NavbarHomePage from './Components/Home/NavbarHomePage';
import Store from "./Components/Store"
import Blog from "./Components/Blog"
import BlogItem1 from "./Components/BlogItem1"
import ContactUs from "./Components/ContactUs"
import AboutUs from "./Components/AboutUs"
import { DataProvider } from './DataContext';
import { AppContext } from './Components/Context/AppContextProvider';
import CookieConsent from './Components/Cookie/CookieConsent';
import DeleteMyAccount from './Components/DeleteMyAccount';
import DeleteMyAccountSuccess from './Components/DeleteMyAccountSuccess';

const ScrollToTopOnNavigate = () => {
    const location = useLocation();
  
    useEffect(() => {
      console.log('Location changed:', location.pathname);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [location.pathname]);
  
    return null;
  };
  
  const Loader = () => (
    <div className="loader">
      <div className="loading-bar loading-bar--active" />
    </div>
  );
  
  const AppContent = () => {
    const location = useLocation();
    const { loading, setLoading } = useContext(AppContext);
  
    useEffect(() => {
      console.log('Location changed in AppContent:', location.pathname);
      setLoading(true);
      const timer = setTimeout(() => {
        console.log('Timer finished, setting loading to false for location:', location.pathname);
        setLoading(false);
      }, 2000); // 3 seconds for testing
      return () => clearTimeout(timer);
    }, [location.pathname, setLoading]);
  
    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <div className="App">
            <ScrollToTopOnNavigate />
            <CookieConsent />
            <Routes>
                <Route exact path="/" element={ <Home /> } />
                <Route path="/teleconsultation" element={ <Teleconsultation /> } />
                <Route path="/teleconsultation/select-specialist" element={ <TeleconsultationSelectDoctor /> } />
                <Route path="/teleconsultation/book-specialist" element={ <TeleconsultationConfirmBooking /> } />
                <Route path="/wellness-plans" element={ <WellnessPlans /> } />
                <Route path="/wellness-plans/menstrual-wellbeing-op1" element={ <WellnessPlansMenstrualWellbeing /> } />
                <Route path="/wellness-plans/checkout" element={ <WellnessPlansCheckout /> } />
                <Route path="/wellness-plans/checkout/login" element={ <WellnessPlansCheckoutLogin /> } />
                <Route path="/wellness-plans/checkout/login/otp" element={ <WellessPlansCheckoutLoginOtp /> } />
                <Route path="/wellness-plans/checkout/login/success" element={ <WellnessPlansCheckoutLoginSuccess /> } />
                <Route path="/corporate" element={ <Corporate /> } />
                <Route path="/workshops" element={ <Workshop /> } />
                <Route path="/direct-plans" element={ <DirectPlans /> } />
                <Route path="/selfprognosis" element={ <SelfPrognosis /> } />
                <Route path="/selfprognosis/know-your-wellness" element={ <KnowYourWellness1 /> } />
                <Route path="/selfprognosis/know-your-wellness/start-journey" element={ <KnowYourWellness2 /> } />
                <Route path="/selfprognosis/know-your-wellness/bmi" element={ <KnowYourWellness3 /> } />
                <Route path="/selfprognosis/know-your-wellness/pain-assessment" element={ <KnowYourWellness5 /> } />
                <Route path="/selfprognosis/know-your-wellness/pain-assessment-2" element={ <KnowYourWellness6 /> } />
                <Route path="/selfprognosis/know-your-wellness/activity-assessment" element={ <KnowYourWellness8 /> } />
                <Route path="/selfprognosis/know-your-wellness/activity-assessment-2" element={ <KnowYourWellness9 /> } />
                <Route path="/selfprognosis/know-your-wellness/sleep-assessment" element={ <KnowYourWellness11 /> } />
                <Route path="/selfprognosis/know-your-wellness/sleep-assessment-2" element={ <KnowYourWellness12 /> } />
                <Route path="/selfprognosis/know-your-wellness/stress" element={ <KnowYourWellness14 /> } />
                <Route path="/selfprognosis/know-your-wellness/perceived-stress" element={ <KnowYourWellness15 /> } />
                <Route path="/selfprognosis/know-your-wellness/occupational-stress" element={ <KnowYourWellness20 /> } />
                <Route path="/selfprognosis/know-your-wellness/wellness-report" element={ <KnowYourWellness24 /> } />
                <Route path="/store" element={ <Store /> } />
                <Route path="/blog" element={ <Blog /> } />
                <Route path="/blog/details" element={ <BlogItem1 /> } />
                <Route path="/contact-us" element={ <ContactUs /> } />
                <Route path="/about-us" element={ <AboutUs /> } />
                <Route path='/delete-my-account' element={<DeleteMyAccount />} />
                <Route path='/delete-my-account/success' element={<DeleteMyAccountSuccess />} />
                
                {/* <Route element={<NavbarHomePage />} /> */}
                </Routes>
          </div>
        )}
      </>
    );
  };
  
  export default AppContent;