import { Assets } from '../Assets';

import { useState } from 'react';

const AreaOfConcernTeleconsultation = () => {
    const imageItems = [
        { 
            id: 1,
            concern: "Libido",
            concernImg: Assets.libido,
            arrowSrc: Assets.arrowUpRightPink,
            arrowHoverSrc: Assets.arrowUpRight,
            },
        { 
            id: 2,
            concern: "Cognitive",
            concernImg: Assets.cognitive,
            arrowSrc: Assets.arrowUpRightPink,
            arrowHoverSrc: Assets.arrowUpRight,
        },
        { 
            id: 3,
            concern: "Skin and Hair",
            concernImg: Assets.skinAndHair,
            arrowSrc: Assets.arrowUpRightPink,
            arrowHoverSrc: Assets.arrowUpRight,
        },
        { 
            id: 4,
            concern: "Anxiety, Stress and Depression",
            concernImg: Assets.anxietyStressDepression,
            arrowSrc: Assets.arrowUpRightPink,
            arrowHoverSrc: Assets.arrowUpRight,
        },
    ];
    const ImageItem = ({ item }) => {
        const [isHovered, setHovered] = useState(false);
        return (

            <li>
                <img src={ item.concernImg } alt={ item.concern } />
                <div className="concernTitleWrapper">
                    <p className="concernTitle">{ item.concern }</p>
                </div>
                <div className="consultNowButtonWrapper">
                    <button id="consultNowButton" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                        Consult Now
                        <img src={ isHovered ? item.arrowHoverSrc : item.arrowSrc } />
                    </button>
                </div>
            </li>

        );
    }
    return ( 
        <div className="areaOfConcern">
           <div className="teleconsMainContentBannerBottom">
                <div className="teleconsMainContentBannerBottomWrapper">
                    <img alt='' src={ Assets.teleconsBottomBanner } />
                </div>
            </div>
            <div className="areaOfConcernWrapper">
                <div className="areaOfConcernHeadingAndLinkContent">
                    <div className="areaOfConcernContentHeading">
                        <p className="areaOfConcernContentHeading1">Area of Concern</p>
                        <p className="areaOfConcernContentHeading2">Consult top doctors online</p>
                        <p className="areaOfConcernContentHeading3">For any health concern</p>
                    </div>
                    <div className="areaOfConcernContentList">
                        <a href="/">Lifestyle</a>
                        <a href="/">Menstrual</a>
                        <button>View all</button>
                    </div>
                </div>
                <div className="areaOfConcernConcerns">
                    <ul className="areaOfConcernUl">
                        {imageItems.map(item => (
                            <ImageItem key = { item.id } item = { item } />
                        ))}
                    </ul>
                </div>
            </div>
            
            
        </div>
    );
}
 
export default AreaOfConcernTeleconsultation;