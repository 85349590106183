import React from 'react'
import FeaturedAndFooter from "./Home/FeaturedAndFooter"
import NavbarHomePage from './Home/NavbarHomePage';
import AccountMainContent from './Account/AccountMainContent';
import AccountDeletedSuccessfullyContent from './Account/AccountDeletedSuccessfullyContent';

function DeleteMyAccountSuccess() {
  return (
    <div className="homePage myaccountpage">
            <NavbarHomePage />
            <AccountMainContent />
            <AccountDeletedSuccessfullyContent />
            <div className="blogFooter">
            <FeaturedAndFooter />
            </div>
        </div>
  )
}

export default DeleteMyAccountSuccess
